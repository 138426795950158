import { Button, Flex, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { SubOrganizationsTable } from '../../components/DataTables/SubOrganizationsTable';
import Icon from '../../components/Icon';
import { usePostChildOrganization } from '../../hooks/mutations/usePostChildOrganization';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useAuth } from '../../hooks/useAuth';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import '../../scss/pages/people-orgs.scss';
import { CreateSubOrgModal } from './components/CreateSubOrgModal';
import { useNotifications } from '../../hooks/utils/useNotifications';

export const SubOrganizations = () => {
  const { t } = useTranslation();
  const { user, checkUserAccess } = useAuth();
  const [currentOrgId] = useOrganizationId(null);
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });
  const hasWriteAccess = checkUserAccess('write');
  const { success: showSuccessNotification, error: showErrorNotification } =
    useNotifications();

  const hasEditPermissions = useMemo(
    () =>
      Boolean(
        (!!user?.internalRoles?.length &&
          (user?.internalRoles?.includes('staff') ||
            user?.internalRoles?.includes('admin'))) ||
          (!!user?.isAdminOfOrganizations?.length &&
            currentOrgId &&
            user?.isAdminOfOrganizations?.includes(currentOrgId)),
      ),
    [user],
  );

  const [createSubOrgModalOpened, setCreateSubOrgModalOpened] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const { mutateAsync: postChildOrganization, isLoading: isCreatingChildOrg } =
    usePostChildOrganization();

  const handleCreateOrganization = async ({
    name,
    organizationId,
  }: {
    name: string;
    organizationId?: string;
  }) => {
    const childOrg = await postChildOrganization(
      name || t('page.userSettings.organization.sub-organizations.new-org-placeholder'),
    );
    setCreateSubOrgModalOpened(false);

    if (childOrg?.success && childOrg.data?.length === 1) {
      showSuccessNotification({
        title: t('page.userSettings.organization.sub-organizations.create-org-success'),
        message: t(
          'page.userSettings.organization.sub-organizations.create-org-success-message',
          { orgName: name },
        ),
      });
    } else {
      showErrorNotification({
        title: t('page.userSettings.organization.sub-organizations.create-org-fail'),
        message: t(
          'page.userSettings.organization.sub-organizations.create-org-fail-message',
          { orgName: name },
        ),
      });
    }
  };

  const isParentOrg =
    !fetchedCurrentOrganization?.parent || fetchedCurrentOrganization?.parent?.length < 1;

  return (
    <div className="page-people anim-slideInDownShort">
      {!hasEditPermissions && <Navigate to="/settings" />}
      {!isParentOrg && <Navigate to="/settings/people" />}
      <Flex justify="space-between">
        <div className="header-flex">
          <Title order={2}>
            {t('page.userSettings.organization.sub-organizations.header')}
          </Title>
        </div>
        {hasEditPermissions && hasWriteAccess && (
          <Button onClick={() => setCreateSubOrgModalOpened(true)}>
            {t('page.userSettings.organization.sub-organizations.add-org')}
          </Button>
        )}
      </Flex>
      <p>{t('page.userSettings.organization.sub-organizations.description')}</p>

      <SubOrganizationsTable hasEditPermissions={hasEditPermissions && hasWriteAccess} />
      <CreateSubOrgModal
        isCreating={isCreatingChildOrg}
        opened={createSubOrgModalOpened}
        onCancel={() => setCreateSubOrgModalOpened(false)}
        onConfirm={handleCreateOrganization}
        validationErrors={validationErrors}
      />
    </div>
  );
};

export default SubOrganizations;
