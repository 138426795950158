import type { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface Params {
  assetId?: string;
  enabled?: boolean;
  limit?: number;
  organizationId?: string;
  page?: number;
  sortColumn?: string;
  sortType?: string;
}

export const useFetchVulns = ({
  assetId,
  enabled = true,
  limit,
  organizationId,
  page,
  sortColumn = 'epssScore',
  sortType = 'desc',
}: Params) => {
  let fetchUrl = `vulnerabilities/organization`;
  if (organizationId) {
    fetchUrl += `/${organizationId}`;
  }
  fetchUrl += '?';
  if (assetId) {
    fetchUrl += `&confineToAsset=${assetId}`;
  }
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":"${sortType === 'asc' ? 1 : -1}","_id":"${
      sortType === 'asc' ? 1 : -1
    }"}`;
  }
  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: ['vulns', assetId && { assetId }, `/${fetchUrl}`].filter(Boolean),
    queryFn: () => hitApi.get(fetchUrl),
    select: (data: {
      data: InterfaceVulnerability[];
      queryInfo: { countVulnerabilities: number };
    }) => {
      return {
        ...data,
        data: data.data.filter((vuln) => vuln?._id),
      };
    },
    onError: (error) => {
      captureExceptionWithMessage(
        `Unable to fetch vulnerabilities for asset ${assetId}: `,
        error,
      );
    },
  });
};
