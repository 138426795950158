import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface Params {
  vendorId: string;
}

interface Options {
  staleTime?: number;
}

export const useFetchVendor = ({ vendorId }: Params, options?: Options) => {
  const fetchUrl = `vendor/${vendorId}`;

  return useQuery({
    enabled: !!vendorId,
    queryKey: ['vendor', { _id: vendorId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as any,
    onError: (error) => {
      captureExceptionWithMessage('Unable to fetch vendor: ', error);
    },
    staleTime: options?.staleTime,
  });
};
