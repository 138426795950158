import type { InterfaceSbom } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export type SbomtargetStatus = 'successful' | 'pending' | 'failed';

interface Params {
  assetId?: string;
  limit?: number;
  page?: number;
  searchText?: string;
  sortColumn?: string;
  sortType?: string;
  targetStatus?: SbomtargetStatus;
}

interface Options {
  enabled?: boolean;
  refetchInterval?: number;
  staleTime?: number;
  keepPreviousData?: boolean;
}

export const useFetchOssIngests = (
  { assetId, limit, page, searchText, sortColumn, sortType, targetStatus }: Params,
  options?: Options,
) => {
  let fetchUrl = `oss-ingests?`;
  if (assetId) {
    fetchUrl += `&assetId=${assetId}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":${sortType === 'asc' ? 1 : -1}}`;
  }
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (searchText) {
    fetchUrl += `&searchText=${searchText}`;
  }
  if (targetStatus) {
    fetchUrl += `&targetStatus=${targetStatus}`;
  }
  fetchUrl.replace('?&', '?');

  return useQuery({
    queryKey: ['ossingests', assetId && { assetId }, `/${fetchUrl}`].filter(Boolean),
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => ({
      data: data?.data as InterfaceSbom[],
      queryInfo: data?.queryInfo as { totalReturn: number; totalCount: number },
    }),
    onError: (error) => {
      captureExceptionWithMessage(
        `Unable to fetch single OSS Ingest for asset ${assetId}: `,
        error,
      );
    },
    enabled: options?.enabled,
    refetchInterval: options?.refetchInterval,
    staleTime: options?.staleTime,
    keepPreviousData: options?.keepPreviousData,
  });
};
