import { AssetLevelOfSupport } from '@manifest-cyber/types/interface/dbTables';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import hitApi from '../../api';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { InterfaceFullAsset } from '../../types/FullInterfaces';
import { APIResponse } from '../../types/api_response.model';

export interface MappedAsset extends InterfaceFullAsset {
  lastReleaseDateText?: string;
  levelOfSupportText?: string;
  endOfSupportText?: string;
}

export interface FetchOptions {
  refetchInterval?: number;
  staleTime?: number;
  keepPreviousData?: boolean;
}

export const ASSET_QUERY_CACHE_PREFIX = 'asset';

export interface AssetMapperTranslations {
  activelyMaintained: string;
  noMaintained: string;
  abandoned: string;
  unknown: string;
}

export const useFetchAsset = ({
  assetId,
  organizationId,
  options,
  translations,
}: {
  assetId?: string;
  organizationId?: string;
  options?: FetchOptions;
  translations?: AssetMapperTranslations;
}): UseQueryResult<MappedAsset, unknown> => {
  const fetchUrl = `asset/${assetId}${organizationId ? `/${organizationId}` : ''}`;

  return useQuery({
    enabled: !!assetId,
    queryKey: [ASSET_QUERY_CACHE_PREFIX, { _id: assetId }, `/${fetchUrl}`],
    queryFn: async () => {
      const response = (await hitApi.get(fetchUrl)) as APIResponse<InterfaceFullAsset[]>;
      const asset = (await rejectFailedRequestOrEmptyData(response)).data[0];

      if (!asset) {
        throw new Error('Asset not found');
      }

      const levetOfSupportMap: Record<AssetLevelOfSupport, string> = {
        ACTIVELY_MAINTAINED: translations?.activelyMaintained || '',
        NO_LONGER_MAINTAINED: translations?.noMaintained || '',
        ABANDONED: translations?.abandoned || '',
        UNKNOWN: translations?.unknown || '',
      };

      const mappedAsset: MappedAsset = {
        ...asset,
        lastReleaseDateText: asset.lastReleaseDate
          ? DateTime.fromISO(asset.lastReleaseDate).toLocaleString(DateTime.DATE_MED)
          : '',
        endOfSupportText: asset.endOfSupport
          ? DateTime.fromISO(asset.endOfSupport).toLocaleString(DateTime.DATE_MED)
          : '',
        levelOfSupportText: asset.levelOfSupport
          ? levetOfSupportMap[asset.levelOfSupport]
          : asset.levelOfSupport || '',
      };

      return mappedAsset;
    },
    refetchInterval: options?.refetchInterval,
    staleTime: options?.staleTime,
    keepPreviousData: options?.keepPreviousData,
  });
};
