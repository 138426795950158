import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface VulnImpactCountApiResponse {
  assetsCount?: number;
  componentsCount?: number;
  productsCount?: number;
}

export const useFetchVulnImpactCount = (vulnerabilityId: string, activeOnly: boolean) => {
  const fetchUrl = `vulnerability/${vulnerabilityId}/impactCount${activeOnly ? `?activeOnly=true` : ''}`;

  return useQuery({
    queryKey: ['vulnImpactCount', { _id: vulnerabilityId, activeOnly }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as VulnImpactCountApiResponse,
    onError: (error) => {
      captureExceptionWithMessage('Unable to fetch vulnerability impact count: ', error);
    },
  });
};
