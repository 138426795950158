import { Button, Loader } from '@mantine/core';
import { LoginFlow } from '@ory/client';
import { UserAuthForm } from '@ory/elements';
import { AxiosError } from 'axios';
import Lottie from 'lottie-react-web';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Input, InputGroup } from 'rsuite';
import hitApi from '../../api';
import { UserApi } from '../../api/user/user.api';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../global.constants';
import { useAuth } from '../../hooks/useAuth';
import { useLastPath } from '../../hooks/utils/useLastPath';
import * as loginLotti from '../../img/illustrations/login/login.json';
import * as loginSubmittedLotti from '../../img/illustrations/login/loginSubmitted.json';
import { sdk } from '../../lib/orySDK';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../scss/pages/login.scss';

/**
 * A note on Login:
 * - Auth is done via session cookie
 * - Route will first attempt to fetch our profile.
 * - If unsuccessful, prompt for login
 * - User enters email, we start a new attempt
 * - We receive back a manualToken and do 2 things:
 * - a) We start an interval to check to fetch our profile
 * - - a1) If successful, user clicked magic link and now has a session/cookie.
 * - b) We allow user to attempt manual secret entry and attach manualToken
 * - - b2) If successful, remote will set cookie and we attempt to fetch profile, etc.
 * ----
 * - In any case, a user won't be logged in until we can successfully fetch profile data (GET /user).
 */

/**
 *
 * @returns
 */

const storageLoginEmail = 'login-email';

const Login = () => {
  // Grab user context
  const { login, logout, user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [lastPath] = useLastPath();

  // States
  const [isCheckedProfile, setIsCheckedProfile] = useState(false);
  const [canFetchUserProfile, setCanFetchUserProfile] = useState(false);
  const [isAuthSubmitting, setIsAuthSubmitting] = useState(false);
  const [isAuthSubmitted, setIsAuthSubmitted] = useState(false);
  const [SSOProviders, setSSOProviders] = useState([]);
  const hasSSO = SSOProviders.length > 0;
  // const [isManualCodeSubmitted, setIsManualCodeSubmitting] = useState(false);
  const [userHasNoOrgs, setUserHasNoOrgs] = useState(false);
  const [logoutReason, setLogoutReason] = useState<string | null>(null);

  // SSO
  const [flow, setFlow] = useState<LoginFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const returnTo = searchParams.get('return_to');
  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        // the flow data contains the form fields, error messages and csrf token
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((error) => {
          // something unexpected went wrong and the flow was not set
          captureExceptionWithMessage('Error getFlow', error);
          navigate('/login', { replace: true });
        }),
    [],
  );

  // Create a new login flow
  const createFlow = () => {
    sdk
      .createBrowserLoginFlow({
        refresh: true,
        ...(returnTo && { returnTo: returnTo }),
      })
      // flow contains the form fields and csrf token
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ['flow']: flow.id });
        // Set the flow data
        setFlow(flow);
      })
      .catch((error) => {
        // something unexpected went wrong and the flow was not set
        captureExceptionWithMessage('Error createFlow', error);
        navigate('/login', { replace: true });
      });
  };

  useEffect(() => {
    const flowId = searchParams.get('flow');
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }

    if (hasSSO) {
      createFlow();
    }
  }, [SSOProviders]);

  // Values
  const [email, setEmail] = useState('');
  const [, setManualToken] = useState('');
  // const [manualSecret, setManualSecret] = useState('');
  const [rememberedEmail, setRememberedEmail] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [incomingError, setIncomingError] = useState('');

  let verificationCheckIntervalId: any = null;
  let verificationCheckIntervalCount = 0;

  useEffect(() => {
    let logoutReason: string | null = location?.search?.split('logout=')[1] || null;
    if (logoutReason) {
      logoutReason = logoutReason?.split('&')[0] || null;
    }

    if (logoutReason && logoutReason?.length > 0) {
      setLogoutReason(
        t(`page.login.logout-reason.${logoutReason}`) ||
          t(`page.login.logout-reason.default`),
      );
    }
  }, [location?.search, t]);

  // Check for incoming errors to display
  useEffect(() => {
    if (location && location.search) {
      const locationSplit = location.search.split('?err=', 2);
      if (locationSplit && locationSplit[1]) {
        // Should have some errors.
        const incomingErrors: string = `${locationSplit[1]}`.replace(/[^a-z0-9-]/gi, '');
        setIncomingError(incomingErrors);
        setUserHasNoOrgs(true);
      }
    }
  }, [setIncomingError, location]);

  // Attempt to fetch user profile on mount or user change
  // Also check for a logout reason to display (i18n)
  useEffect(() => {
    setEmail(window.localStorage.getItem(storageLoginEmail) || '');

    const getUserProfile = async () => {
      try {
        const fetchedUser = await UserApi.getUser();
        login?.(fetchedUser);
        setUserHasNoOrgs(!fetchedUser.hasOrganizations);
        setCanFetchUserProfile(true);
      } catch (error) {
        const isForbiddenError = (error as AxiosError)?.response?.status === 401;
        if (isForbiddenError && user?._id) {
          // We don't have a cookie but we have a user - we should do some cleanup here.
          logout?.('no-cookie-but-user', { shouldRedirectToLogin: false });
        }
        captureExceptionWithMessage('Error getting user profile in Login page', error);
      }

      setIsCheckedProfile(true);
    };

    getUserProfile();
  }, [user, login, logout]);

  // Wipe interval on dismount.
  useEffect(
    () => () => clearInterval(verificationCheckIntervalId),
    [verificationCheckIntervalId],
  );

  if (user && canFetchUserProfile && !userHasNoOrgs) {
    return <Navigate to={lastPath || '/'} />;
  }

  // Attempts to trigger new login attempt with a given email
  const triggerNewLoginAttempt = async (email: string) => {
    try {
      setIsAuthSubmitting(true);

      if (!email || email.length < 1 || !email.includes('@')) {
        setErrors([t('page.login.email-missing')]);
        setIsAuthSubmitting(false);
        return true;
      } else {
        setErrors([]);
      }

      const newAttempt = await hitApi.post('auth/new', true, {
        filingType: 'email',
        filing: email,
      });

      window.localStorage.setItem(storageLoginEmail, email);

      // If successful, we should get a `data` object back with success: true and `authCodeManualToken`
      if (newAttempt && newAttempt?.data) {
        const { success, errors: attemptErrors, data: attemptData } = newAttempt;
        if (!success) {
          setErrors(attemptErrors);
          setIsAuthSubmitting(false);
        } else {
          if (attemptData?.SSOProviders && attemptData?.SSOProviders?.length > 0) {
            setSSOProviders(attemptData.SSOProviders);
          } else if (
            attemptData?.authCodeManualToken &&
            attemptData?.authCodeManualToken?.length > 0
          ) {
            setManualToken(attemptData?.authCodeManualToken);
            verificationCheckIntervalId = setInterval(
              () => periodicallyAttemptToFetchProfile(),
              2000,
            );
          }

          setIsAuthSubmitted(true);
        }
      } else {
        setErrors([t('page.login.network-error')]);
        setIsAuthSubmitting(false);
      }
    } catch (err) {
      captureExceptionWithMessage('Error attempting to create new auth attempt', err);
    }
  };

  // Attempts to trigger manual verification via secret
  // const triggerManualSecretSubmission = async (secret: string) => {

  // }

  const periodicallyAttemptToFetchProfile = async () => {
    verificationCheckIntervalCount += 1;

    // Checks run every 2s, link expires in 10 mins
    // If more than 300 intervals have run, kill so we aren't pinging our servers for infinity.
    if (verificationCheckIntervalCount > 300) {
      setRememberedEmail(true);
      setIsAuthSubmitted(false);
      setIsAuthSubmitting(false);
      setErrors([...errors, t('page.login.error-timed-out')]);
      stopPeriodicProfileFetches();
    } else {
      try {
        const thisUserData = await UserApi.getUser();
        login?.(thisUserData);
        setUserHasNoOrgs(!thisUserData.hasOrganizations);
        stopPeriodicProfileFetches();
        setCanFetchUserProfile(true);
      } catch (error) {
        captureExceptionWithMessage('Error getting user profile in Login page', error);
      }
    }
  };

  const stopPeriodicProfileFetches = () => {
    // Stop pinging /login-check
    clearInterval(verificationCheckIntervalId);
    verificationCheckIntervalId = null;
    verificationCheckIntervalCount = 0;
  };

  // Fires on email change
  const onEmailChange = (e: any) => {
    setEmail(e);
  };

  // Handles submission via return key
  const onEmailKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      triggerNewLoginAttempt(email);
    }
  };

  // Delete remembered email from cookies
  const deleteRememberedEmail = () => {
    window.localStorage.removeItem(storageLoginEmail);
    setRememberedEmail(false);
    setEmail('');
  };

  // 'Undo' button after email submission
  const undoEmailSubmission = () => {
    stopPeriodicProfileFetches();
    setRememberedEmail(true);
    setUserHasNoOrgs(false);
    setIncomingError('');
    setIsAuthSubmitted(false);
    setIsAuthSubmitting(false);
  };

  // Changes input icon based on current onEmailSubmit() progress,
  // or based on whether email input contains value
  const renderInputIcon = () => {
    if (isAuthSubmitted) {
      return (
        <InputGroup.Button disabled>
          return <Icon icon="circle-notch" spin />;
        </InputGroup.Button>
      );
    }

    if (rememberedEmail || email.length > 0) {
      return (
        <InputGroup.Button>
          <Icon
            className="delete-email"
            icon="trash"
            onClick={() => deleteRememberedEmail()}
          />
        </InputGroup.Button>
      );
    }

    return (
      <InputGroup.Button disabled>
        <Icon icon="envelope" />
      </InputGroup.Button>
    );
  };

  return (
    <div className="page-login">
      <Helmet>
        <title>{t('page.login.title', { brand: t('app.name') })}</title>
        <meta
          name="description"
          content={t('page.login.title', { brand: t('app.name') })}
        />
      </Helmet>
      <section className="illustration">
        {/* <h1>Know what you're made of.</h1> */}
        {isAuthSubmitted && SSOProviders.length === 0 && (
          <div className="anim-slideInUp">
            <Lottie
              options={{
                // @ts-ignore
                animationData: loginSubmittedLotti?.default,
                // rendererSettings: {
                //   preserveAspectRatio: 'xMidYMid slice',
                // },
              }}
              height="100%"
              width="auto"
              isStopped={false}
              isPaused={false}
            />
          </div>
        )}
        {(!isAuthSubmitted || hasSSO) && (
          <div className="anim-slideInUp">
            <Lottie
              options={{
                // @ts-ignore
                animationData: loginLotti?.default,
                // rendererSettings: {
                //   preserveAspectRatio: 'xMidYMid slice',
                // },
              }}
              height="100%"
              width="auto"
              isStopped={false}
              isPaused={false}
            />
          </div>
        )}
      </section>
      <section className="login-container">
        {!isCheckedProfile && !isAuthSubmitted && (
          <Loading selfContain className="sit-left" />
        )}

        {isCheckedProfile && !isAuthSubmitted && errors.length < 1 && logoutReason && (
          <div className="logout-error anim-slideInDownShort">
            <span>{logoutReason}</span>
          </div>
        )}

        {errors.length > 0 && (
          <div className="submission-errors">
            <span>
              {errors.map((error) => (
                <Trans key={error} i18nKey={error} />
              ))}
            </span>
          </div>
        )}

        {incomingError.length > 0 && (
          <div className="submission-errors">
            <span>{t(`page.login.incoming-error.${incomingError}`)}</span>
          </div>
        )}

        {isCheckedProfile && !isAuthSubmitted && !userHasNoOrgs && (
          <div className="anim-slideInLeftShort">
            <h1>{t('page.login.title')}</h1>
            <p className="lead">{t('page.login.intro')}</p>

            <InputGroup inside className="login-email">
              <Input
                type="text"
                disabled={isAuthSubmitting}
                placeholder={t('page.login.placeholder')}
                onChange={(e) => onEmailChange(e)}
                onKeyDown={(e) => onEmailKeyDown(e)}
                value={email}
              />
              {renderInputIcon()}
            </InputGroup>

            <div className="login-submit-cont">
              <ClickableRegion
                regionLabel={t('page.login.submit-label')}
                className="its-awesome"
                disabled={
                  isAuthSubmitting || !email || email.length < 1 || !email.includes('@')
                }
                onClick={() => triggerNewLoginAttempt(email)}
              >
                <Trans
                  i18nKey={
                    isAuthSubmitting ? 'page.login.submitting' : 'page.login.submit'
                  }
                />
              </ClickableRegion>
            </div>
            <div className="login-terms">
              <p>
                <Trans
                  i18nKey="page.login.legal"
                  values={{
                    tos: t('legal.tos.title'),
                    privacy: t('legal.privacy.title'),
                  }}
                >
                  By signing in or creating an account, you agree to our{' '}
                  <ClickableRegion
                    regionLabel={t('legal.tos.title')}
                    href={TERMS_OF_USE_LINK}
                    target="_blank"
                  >
                    {t('legal.tos.title')}
                  </ClickableRegion>{' '}
                  and
                  <ClickableRegion
                    regionLabel={t('legal.privacy.title')}
                    href={PRIVACY_POLICY_LINK}
                    target="_blank"
                  >
                    {t('legal.privacy.title')}
                  </ClickableRegion>
                  .
                </Trans>
              </p>
            </div>
            {/* CTA removed for now. */}
            {/* <div className="learn-more">
              <div className="learn-more-icon">
                <Logo
                  classNames="learn-more-icon-logo"
                  link="https://manifestcyber.com"
                />
              </div>
              <div className="learn-more-details">
                <span>{t('page.login.learnMore')}</span>
                <ClickableRegion
                  href="https://manifestcyber.com"
                  regionLabel={t('page.login.learnMoreLinkTooltip')}
                >
                  {t('page.login.learnMoreLink')}
                </ClickableRegion>
              </div>
            </div> */}
          </div>
        )}

        {isAuthSubmitted && hasSSO && flow && (
          <UserAuthForm flow={flow}>
            <div className="sso-login anim-slideInLeftShort">
              <h1>
                <Trans i18nKey={`page.login.sso-title`} />
              </h1>
              <div>
                {SSOProviders.map(({ provider, name, providerId }) => (
                  <Button
                    variant="default"
                    key={providerId}
                    name="provider"
                    type="submit"
                    leftIcon={<Icon icon={provider} iconStyle="fab" />}
                    value={providerId}
                  >
                    <Trans i18nKey={`page.login.sso-submit`} values={{ name }} />
                  </Button>
                ))}
              </div>
              <div className="sso-mis">
                <p className="sso-mis-title">{t('page.login.sso-misconfigured-title')}</p>
                <p className="sso-mis-text">{t('page.login.sso-misconfigured-text')}</p>
              </div>
            </div>
          </UserAuthForm>
        )}
        {isAuthSubmitted && SSOProviders.length === 0 && (
          <div className="pending-verification anim-slideInLeftShort">
            <h2>
              <Trans i18nKey={`page.login.pending-sent`} values={{ email }} />
            </h2>
            <p>
              <Trans i18nKey={`page.login.pending-verification`} />
            </p>
            <div className="verification-code-cont">
              <div className="verification-code">
                <Loader color="white" size={18} />
                <span>
                  <Trans i18nKey={`page.login.verification-waiter`} />
                </span>
              </div>
            </div>
            <div className="submission-undo">
              <Trans i18nKey="page.login.revert-submission">
                Wrong email or need to resend? Click&nbsp;
                <ClickableRegion
                  regionLabel={t('page.login.revert-submission-tooltip')}
                  className="login-email-undo"
                  onClick={() => undoEmailSubmission()}
                >
                  <span>here</span>
                </ClickableRegion>
                &nbsp;to resend.
              </Trans>
            </div>
          </div>
        )}

        {isCheckedProfile &&
          userHasNoOrgs &&
          (canFetchUserProfile || incomingError.length > 0) && (
            <div className="pending-verification anim-slideInLeftShort">
              <h2>
                <Trans i18nKey={`page.login.no-orgs.h1`} />
              </h2>
              <p style={{ marginBottom: '20px' }}>
                <Trans i18nKey={`page.login.no-orgs.details`} />
              </p>
              <p>
                <Trans i18nKey={`page.login.no-orgs.cta`} />
              </p>
              <div className="verification-code-cont"></div>
              <div className="submission-undo">
                <Trans i18nKey="page.login.no-orgs.revert-submission">
                  Wrong email or need to resend? Click&nbsp;
                  <ClickableRegion
                    regionLabel={t('page.login.revert-submission-tooltip')}
                    className="login-email-undo"
                    onClick={() => undoEmailSubmission()}
                  >
                    <span>here</span>
                  </ClickableRegion>
                  &nbsp;to resend.
                </Trans>
              </div>
            </div>
          )}
      </section>
    </div>
  );
};

export default Login;
