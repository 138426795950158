import { useQuery } from '@tanstack/react-query';
import {
  GetSharedSbomsParams,
  SharedSbomsApi,
} from '../../api/sharedSboms/sharedSboms.api';

interface Options {
  enabled?: boolean;
}

export const getSharedSbomsCacheKey = ({
  limit,
  page,
  sort,
  portalName,
}: Parameters<typeof SharedSbomsApi.getUrl>[0]) => [
  'shared-elements',
  SharedSbomsApi.getUrl({ limit, page, sort, portalName }),
];

export const useSharedSboms = (
  { limit, page, sort, texts, portalName }: GetSharedSbomsParams,
  options?: Options,
) => {
  return useQuery({
    refetchInterval: 1000 * 15,
    enabled: options?.enabled,
    keepPreviousData: true,
    queryKey: getSharedSbomsCacheKey({ limit, page, sort, portalName }),
    queryFn: () => SharedSbomsApi.getElements({ limit, page, sort, texts, portalName }),
  });
};
