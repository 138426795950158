import { SbomEnrichmentSource } from '@manifest-cyber/types/interface/db';
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Modal,
  Radio,
  Select,
  Table,
  Text,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileType } from 'rsuite/esm/Uploader';
import hitApi from '../../api';
import Icon from '../../components/Icon';
import featureFlagDisciminators from '../../configs/featureFlags';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { truncateString } from '../../lib/truncateString';
import styles from './UploadSettings.module.scss';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  files: FileType[];
}

interface FileStatus {
  relationship: string;
  isActive: boolean;
}

export const UploadSettingsModal = ({ isOpen, closeModal, files }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [advancedView, setAdvancedView] = useState(false);
  const [currentOrgId] = useOrganizationId(null);

  const {
    data: fetchedCurrentOrganization,
    isLoading: isLoadingCurrentOrganization,
    isError: isErrorLoadingCurrentOrganization,
  } = useFetchOrganization({ organizationId: currentOrgId });

  const form = useForm({
    initialValues: {
      relationship: 'first',
      isActive: true,
      enrichmentSourceOverride:
        fetchedCurrentOrganization?.defaultSettings?.enabledSbomEnrichmentSource ??
        undefined,
      fileStatuses: files.map(() => ({
        relationship: 'first',
        isActive: true,
      })),
    },
    validate: {
      relationship: (value) => (value ? null : t('page.uploads.relationshipRequired')),
    },
  });

  useEffect(() => {
    form.setValues({
      relationship: 'first',
      isActive: true,
      enrichmentSourceOverride:
        fetchedCurrentOrganization?.defaultSettings?.enabledSbomEnrichmentSource ??
        undefined,
      fileStatuses: files.map(() => ({
        relationship: 'first',
        isActive: true,
      })),
    });
  }, [files]);

  const handleConfirm = async (values: {
    relationship: string;
    isActive: boolean;
    enrichmentSourceOverride?: SbomEnrichmentSource;
    fileStatuses: FileStatus[];
  }) => {
    setLoading(true);
    for (const [index, file] of files.entries()) {
      try {
        if (!file.blobFile) {
          captureExceptionWithMessage(`File blob is undefined for file: ${file.name}`);

          continue;
        }

        const formData = new FormData();
        formData.append('file', file.blobFile);
        formData.append(
          'relationship',
          advancedView
            ? values.fileStatuses[index]?.relationship || ''
            : values.relationship,
        );
        formData.append(
          'isActive',
          (advancedView
            ? values.fileStatuses[index]?.isActive || false
            : values.isActive
          ).toString(),
        );

        if (!advancedView && values.enrichmentSourceOverride) {
          formData.append('enrichmentSourceOverride', 'PARLAY');
        }

        const res = await hitApi.putFiles(`/upload`, formData, true);
        const { success } = res;
        if (success === false) throw new Error(`Failed to upload ${file.name}`);
      } catch (e) {
        captureExceptionWithMessage('handleConfirm', e);
      }
    }
    handleDone();
  };

  const handleDone = () => {
    setLoading(false);
    form.reset();
    closeModal();
  };

  const selectAllRows = (key: 'relationship' | 'isActive', value: string | boolean) => {
    const data = [...form.values.fileStatuses];
    // @ts-expect-error TODO: fix type error when updating this file
    data.forEach((_, index) => (data[index][key] = value));
    form.setFieldValue('fileStatuses', data);
  };

  const getButtonClass = (type: 'first' | 'third') => {
    const allOfType = form.values.fileStatuses.every(
      (status) => status.relationship === type,
    );
    return allOfType
      ? type === 'first'
        ? styles.relationshipFirstParty
        : styles.relationshipThirdParty
      : styles.relationshipDefault;
  };

  const getRowButtonClass = (index: number, type: 'first' | 'third') => {
    const status = form.values.fileStatuses[index];
    return status?.relationship === type
      ? type === 'first'
        ? styles.relationshipFirstParty
        : styles.relationshipThirdParty
      : styles.relationshipDefault;
  };

  const getSelectValue = () => {
    const allActive = form.values.fileStatuses.every((status) => status.isActive);
    const allInactive = form.values.fileStatuses.every((status) => !status.isActive);
    if (allActive) return 'active';
    if (allInactive) return 'inactive';
    return 'mixed';
  };

  const handleHeaderSelectChange = (value: string | null) => {
    if (value !== 'mixed') {
      selectAllRows('isActive', value === 'active');
    }
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={() => {
        setAdvancedView(false);
        closeModal();
      }}
      size={advancedView ? 'auto' : 'md'}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex direction="column" gap={4}>
              <Modal.Title>{t('page.uploads.uploadSettings')}</Modal.Title>
              <Text size="sm" className="add-assets-to-product__subtitle">
                {t('page.uploads.appliedToSBOMs', {
                  sbomCount: files.length ?? 0,
                  count: files.length ?? 0,
                })}
              </Text>
            </Flex>
            <Modal.CloseButton />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((values) => handleConfirm(values))}>
            <Flex direction="column" gap={24}>
              {advancedView ? (
                <Flex direction="column" gap={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th className={styles.tableHead}>{t('page.uploads.sbomName')}</th>
                        <th className={styles.tableHead}>
                          <Flex align={'flex-start'} direction="column">
                            <span>{t('page.uploads.markAllAs')}</span>
                            <Button.Group>
                              <Button
                                variant="default"
                                size="xs"
                                onClick={() => selectAllRows('relationship', 'first')}
                                className={getButtonClass('first')}
                              >
                                {t('page.uploads.firstPartyFull')}
                              </Button>
                              <Button
                                variant="default"
                                size="xs"
                                onClick={() => selectAllRows('relationship', 'third')}
                                className={getButtonClass('third')}
                              >
                                {t('page.uploads.thirdPartyFull')}
                              </Button>
                            </Button.Group>
                          </Flex>
                        </th>
                        <th className={styles.tableHead}>
                          <Flex align={'center'} justify={'flex-end'} gap={6}>
                            {getSelectValue() !== 'mixed' ? (
                              <div
                                className={
                                  form.values.fileStatuses.every(
                                    (status) => status.isActive,
                                  )
                                    ? styles.fileActive
                                    : styles.fileInactive
                                }
                              ></div>
                            ) : null}
                            <Select
                              size="xs"
                              classNames={{
                                root: styles.selectRoot,
                                item: styles.selectItem,
                                input: styles.selectInput,
                              }}
                              value={getSelectValue()}
                              onChange={(value) => handleHeaderSelectChange(value)}
                              data={[
                                {
                                  value: 'active',
                                  label: t('page.uploads.activeOption'),
                                },
                                {
                                  value: 'inactive',
                                  label: t('page.uploads.inactiveOption'),
                                },
                                {
                                  value: 'mixed',
                                  label: '--',
                                  disabled: true,
                                },
                              ]}
                            />
                          </Flex>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {files.map((file, index) => (
                        <tr key={file.name}>
                          <td>{file?.name ? truncateString(file?.name, 30) : null}</td>
                          <td>
                            <Button.Group>
                              <Button
                                variant="default"
                                size="xs"
                                onClick={() => {
                                  const newFileStatuses = [...form.values.fileStatuses];
                                  if (newFileStatuses[index]) {
                                    newFileStatuses[index]!.relationship = 'first';
                                    form.setFieldValue('fileStatuses', newFileStatuses);
                                  }
                                }}
                                className={getRowButtonClass(index, 'first')}
                              >
                                {t('page.uploads.firstPartyFull')}
                              </Button>
                              <Button
                                variant="default"
                                size="xs"
                                onClick={() => {
                                  const newFileStatuses = [...form.values.fileStatuses];
                                  if (newFileStatuses[index]) {
                                    newFileStatuses[index]!.relationship = 'third';
                                    form.setFieldValue('fileStatuses', newFileStatuses);
                                  }
                                }}
                                className={getRowButtonClass(index, 'third')}
                              >
                                {t('page.uploads.thirdPartyFull')}
                              </Button>
                            </Button.Group>
                          </td>
                          <td>
                            <Flex align={'center'} gap={6}>
                              <div
                                className={
                                  form.values.fileStatuses[index]?.isActive
                                    ? styles.fileActive
                                    : styles.fileInactive
                                }
                              ></div>
                              <Select
                                size="xs"
                                classNames={{
                                  root: styles.selectRoot,
                                  item: styles.selectItem,
                                  input: styles.selectInput,
                                }}
                                value={
                                  form.values.fileStatuses[index]?.isActive
                                    ? 'active'
                                    : 'inactive'
                                }
                                onChange={(value) => {
                                  const newFileStatuses = [...form.values.fileStatuses];
                                  if (newFileStatuses[index]) {
                                    newFileStatuses[index]!.isActive = value === 'active';
                                    form.setFieldValue('fileStatuses', newFileStatuses);
                                  }
                                }}
                                data={[
                                  {
                                    value: 'active',
                                    label: t('page.uploads.activeOption'),
                                  },
                                  {
                                    value: 'inactive',
                                    label: t('page.uploads.inactiveOption'),
                                  },
                                ]}
                              />
                            </Flex>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Flex>
              ) : (
                <>
                  <Flex direction="column" gap={12}>
                    <Text className={styles.fieldTitle}>
                      {t('page.uploads.selectSBOMSource')}{' '}
                      <span className={styles.requiredStar}>*</span>
                    </Text>
                    <Radio.Group {...form.getInputProps('relationship')} required>
                      <Grid gutter={12} grow>
                        <Grid.Col span={6}>
                          <Card
                            shadow="sm"
                            padding="lg"
                            withBorder
                            className={styles.fieldCard}
                          >
                            <Flex justify="space-between">
                              <Radio
                                value="first"
                                label={t('page.uploads.firstParty')}
                                classNames={{ label: styles.fieldLabel }}
                              />
                              <Tooltip
                                classNames={{ tooltip: styles.tooltip }}
                                label={t('page.uploads.firstPartyTooltip')}
                                withArrow
                              >
                                <span>
                                  <Icon icon="info-circle" className={styles.infoIcon} />
                                </span>
                              </Tooltip>
                            </Flex>
                          </Card>
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <Card
                            shadow="sm"
                            padding="lg"
                            withBorder
                            className={styles.fieldCard}
                          >
                            <Flex justify="space-between">
                              <Radio
                                value="third"
                                label={t('page.uploads.thirdParty')}
                                classNames={{ label: styles.fieldLabel }}
                              />
                              <Tooltip
                                classNames={{ tooltip: styles.tooltip }}
                                label={t('page.uploads.thirdPartyTooltip')}
                                withArrow
                              >
                                <span>
                                  <Icon icon="info-circle" className={styles.infoIcon} />
                                </span>
                              </Tooltip>
                            </Flex>
                          </Card>
                        </Grid.Col>
                      </Grid>
                    </Radio.Group>
                  </Flex>

                  <Flex direction="column" justify="center" gap={12}>
                    <Text className={styles.fieldTitle}>
                      {t('page.uploads.additionalOptions')}
                    </Text>

                    <Card
                      shadow="sm"
                      padding="lg"
                      withBorder
                      className={styles.fieldCard}
                    >
                      <Flex justify="space-between">
                        <Checkbox
                          {...form.getInputProps('isActive', { type: 'checkbox' })}
                          label={t('page.uploads.markAllAsActive')}
                          classNames={{ label: styles.fieldLabel }}
                        />
                        <Tooltip
                          classNames={{ tooltip: styles.tooltip }}
                          label={t('page.uploads.markAllAsActiveTooltip')}
                          withArrow
                        >
                          <span>
                            <Icon icon="info-circle" className={styles.infoIcon} />
                          </span>
                        </Tooltip>
                      </Flex>
                    </Card>
                    <Card
                      shadow="sm"
                      padding="lg"
                      withBorder
                      className={styles.fieldCard}
                    >
                      <Flex justify="space-between">
                        <Checkbox
                          {...form.getInputProps('enrichmentSourceOverride', {
                            type: 'checkbox',
                          })}
                          label={t('page.uploads.enrichSBOMs')}
                          classNames={{ label: styles.fieldLabel }}
                        />
                        <Tooltip
                          classNames={{ tooltip: styles.tooltip }}
                          label={t('page.uploads.enrichSBOMsTooltip')}
                          withArrow
                        >
                          <span>
                            <Icon icon="info-circle" className={styles.infoIcon} />
                          </span>
                        </Tooltip>
                      </Flex>
                    </Card>
                  </Flex>
                </>
              )}
              <Flex justify="flex-end" gap={8}>
                {!advancedView && (
                  <Button
                    variant="default"
                    onClick={() => setAdvancedView(!advancedView)}
                  >
                    {t('global.advanced')}
                  </Button>
                )}
                <Button type="submit" disabled={!form.isValid()} loading={loading}>
                  {t('global.upload')}
                </Button>
              </Flex>
            </Flex>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
