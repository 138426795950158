import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import hitApi from '../api';
import { useAuth } from '../hooks/useAuth';
import { useOrganizationId } from '../hooks/utils/useOrganizationId';
import { captureExceptionWithMessage } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import ClickableRegion from './ClickableRegion';
import Icon from './Icon';
import ImpersonationBar from './ImpersonationBar';
import ManifestSearch from './ManifestSearch';
import UserDropdown from './UserDropdown/UserDropdown';

const safeWords = [
  'pineapple',
  'tinkerbell',
  'tofu',
  'hufflepuff',
  'pikachu',
  'jumanji',
  'zuchini',
  'ukulele',
  'hobbylobby',
];

export const TopBar = ({ isPaidOrg }: { isPaidOrg: boolean }) => {
  const { user } = useAuth();
  const [currentOrgId] = useOrganizationId(null);
  const [currentOrgData, setCurrentOrgData] = useState<any>(null);
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const orgId: any = useRef(null);

  const [currentPageTitle, setCurrentPageTitle] = useState('');

  // Determine title to display
  useEffect(() => {
    const mapRoutesToTitles = [
      {
        path: '/',
        title: t('page.dashboard.topbar-title'),
        icon: null,
      },
      {
        path: '/asset',
        title: t('page.assets.topbar-title'),
        icon: null,
      },
      {
        path: '/assets',
        title: t('page.assets.topbar-title'),
        icon: null,
      },
      {
        path: '/component',
        title: t('page.component.topbar-title'),
        icon: null,
      },
      {
        path: '/components',
        title: t('page.components.topbar-title'),
        icon: null,
      },
      {
        path: '/product',
        title: t('page.product.topbar-title'),
        icon: null,
      },
      {
        path: '/products',
        title: t('page.products.topbar-title'),
        icon: null,
      },
      {
        path: '/administration',
        title: t('page.administration.topbar-title', {
          name: user?.firstName || 'Harry',
        }),
        icon: null,
      },
      {
        path: '/settings',
        title: t('navigation.settings'),
        icon: null,
      },
      {
        path: '/organization',
        title: t('page.organization.topbar-title'),
        icon: null,
      },
      {
        path: '/sboms',
        title: t('page.uploads.topbar-title'),
        icon: null,
      },
      {
        path: '/uploads',
        title: t('page.uploads.topbar-title'),
        icon: null,
      },
      {
        path: '/user',
        title: t('page.user.topbar-title'),
        icon: null,
      },
      {
        path: '/users',
        title: t('page.users.topbar-title'),
        icon: null,
      },
      {
        path: '/vulnerability',
        title: t('page.vulnerability.topbar-title'),
        icon: null,
      },
      {
        path: '/vulnerabilities',
        title: t('page.vulnerabilities.topbar-title'),
        icon: null,
      },
    ];

    if (location?.pathname) {
      const currentTitle = mapRoutesToTitles.filter(
        (x) =>
          x.path === location?.pathname || location?.pathname?.includes(`${x.path}/`),
      );
      if (currentTitle[0] && currentTitle[0].title) {
        setCurrentPageTitle(`${currentTitle[0].title}`);
      }
    } else {
      captureExceptionWithMessage(
        'Unable to determine topbar page title. Location data:',
        location,
      );
    }
  }, [location, t, user?.firstName]);

  // Get organization profile
  useEffect(() => {
    const fetchOrgData = async (currentOrgId?: string) => {
      const getOrgData = await hitApi.get('organization');
      if (getOrgData && getOrgData?.success && getOrgData?.data[0]) {
        setCurrentOrgData(getOrgData?.data[0]);
      }

      orgId.current = currentOrgId;
    };

    if (currentOrgId && orgId.current !== currentOrgId) {
      if (user?.organizations.includes(`${currentOrgId}`)) {
        setIsImpersonating(false);
      } else if (
        user &&
        user?.internalRoles &&
        (user?.internalRoles?.includes('staff') || user?.internalRoles?.includes('admin'))
      ) {
        setIsImpersonating(true);
      }

      fetchOrgData();
    }
  }, [currentOrgId, user]);

  return (
    <div className={`topbar ${isImpersonating ? 'is-impersonating' : ''}`}>
      <div className="left-side">
        {currentPageTitle && (
          <div className="page-title anim-slideInDownShort">
            <Flex gap="md">
              <Tooltip label={t('global.goBack')}>
                <ActionIcon
                  onClick={() => navigate(-1)}
                  variant="transparent"
                  aria-label={t('global.goBack')}
                >
                  <Icon icon="arrow-left" />
                </ActionIcon>
              </Tooltip>
              <Tooltip label={t('global.goForward')}>
                <ActionIcon
                  onClick={() => navigate(1)}
                  variant="transparent"
                  aria-label={t('global.goForward')}
                >
                  <Icon icon="arrow-right" />
                </ActionIcon>
              </Tooltip>
            </Flex>
            <span>{currentPageTitle}</span>
          </div>
        )}
      </div>
      {isImpersonating && <ImpersonationBar currentOrgData={currentOrgData} />}
      <div className="right-side">
        {isPaidOrg && <ManifestSearch condensed showPopular showRecent />}
        <UserDropdown currentOrgData={currentOrgData} />
        {(!user || !user?.organizations || user?.organizations?.length < 1) && (
          <ul className="organization-selector no-orgs">
            <li>
              <ClickableRegion regionLabel="View Profile" href="/profile">
                <Icon icon="user" />
              </ClickableRegion>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default TopBar;
