import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import hitApi from '../../api';
import {
  ComponentsMapperTranslations,
  mapComponent,
  MappedOrganizationComponentWithVulns,
} from '../../api/componentsApi/components.mapper';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { TransformedToClientTypes } from '../../types/transformedToClientType';

interface Params {
  componentId: string;
  organizationId: string;
  translations?: ComponentsMapperTranslations;
}

export const useFetchComponent = ({
  componentId,
  organizationId,
  translations,
}: Params): UseQueryResult<
  {
    component: MappedOrganizationComponentWithVulns;
    versions: MappedOrganizationComponentWithVulns[];
  },
  unknown
> => {
  const fetchUrl = `component/id/${componentId}/${organizationId}`;

  return useQuery({
    queryKey: ['component', { _id: componentId }, `/${fetchUrl}`],
    queryFn: async () => {
      const response: ManifestApiResponse<{
        component: TransformedToClientTypes<InterfaceOrganizationComponent>;
        versions: TransformedToClientTypes<InterfaceOrganizationComponent>[];
        countVersions: number;
      }> = await hitApi.get(fetchUrl);

      const successfulResponse = await rejectFailedRequestOrEmptyData(response);

      return {
        component: mapComponent(successfulResponse.data.component, translations),
        versions: successfulResponse.data.versions.map((component) =>
          mapComponent(component, translations),
        ),
      };
    },
  });
};
