import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import hitApi from '../../../../../api';
import { buildUrlWithParams } from '../../../../../lib/url/buildWithParams/buildWithParams';
import {
  SharedSbomsApiResponse,
  SharedSbomsResponse,
} from '../../types/sharedSboms.interface';

import { rejectFailedRequestOrEmptyData } from '../../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { mapSharedSboms, MapSharedSbomsTexts } from './sharedSboms.mapper';

interface GetUrlParams {
  limit?: number;
  page?: number;
  sort?: {
    column: string;
    type: 1 | -1;
  };
  portalName: string;
}

const getUrl = ({ limit, page, sort, portalName }: GetUrlParams): string => {
  const sortParam = sort
    ? {
        [sort.column]: sort.type,
        _id: sort.type,
      }
    : undefined;

  return buildUrlWithParams(`sharing-portal/${portalName}/sboms`, {
    limit,
    page,
    sort: sortParam,
  });
};

export interface GetSharedSbomsParams extends GetUrlParams {
  texts?: MapSharedSbomsTexts;
}

const getElements = async ({
  limit,
  page,
  sort,
  texts,
  portalName,
}: GetSharedSbomsParams): Promise<SharedSbomsResponse> => {
  const response: SharedSbomsApiResponse = await hitApi.get(
    SharedSbomsApi.getUrl({ limit, page, sort, portalName }),
  );

  if (!response.success) {
    return Promise.reject(response);
  }

  return {
    ...response,
    data: mapSharedSboms(response.data, texts),
  };
};

export interface DownloadSharedSbomResponse {
  fileName: string;
  sbomId: string;
  url: string;
}

const downloadSbom = async (
  sbomId: string,
  organizationId: string,
): Promise<DownloadSharedSbomResponse> => {
  const response: ManifestApiResponse<DownloadSharedSbomResponse[]> = await hitApi.get(
    `sharing-portal/${organizationId}/${sbomId}/download`,
    true,
    {},
    {},
    undefined,
    {
      responseType: 'json',
    },
  );

  const { data } = await rejectFailedRequestOrEmptyData(response);
  const parsedResponse = data[0];
  if (!parsedResponse) {
    throw new Error('No data found');
  }

  return parsedResponse;
};

const downloadSboms = async (
  sbomIds: string[],
  organizationId: string,
): Promise<BlobPart> => {
  const response = (await hitApi.get(
    `sharing-portal/${organizationId}/download/bundle?sbomIds=${sbomIds.join(',')}`,
    true,
    {},
    {},
    undefined,
    { responseType: 'blob' },
  )) as BlobPart | ManifestApiResponse<[]>;

  /* When the endpoint fails, returns a json not  blob  */
  if ((response as ManifestApiResponse<[]>)?.success === false) {
    throw response;
  }

  return response as BlobPart;
};

export const SharedSbomsApi = {
  getElements,
  getUrl,
  downloadSbom,
  downloadSboms,
};
