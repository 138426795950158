import { useMemo, useState } from 'react';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Collapse,
  Flex,
  Radio,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import Icon from '../Icon/Icon';
import { useTranslation } from 'react-i18next';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';
import { useAuth } from '../../hooks/useAuth';
import { useFetchOrganizationUsers } from '../../hooks/queries/useFetchOrganizationUsers';
import { usePutOrganizationUser } from '../../hooks/mutations/usePutOrganizationUser';
import { usePostOrganizationUser } from '../../hooks/mutations/usePostOrganizationUser';
import { useDeleteOrganizationUser } from '../../hooks/mutations/useDeleteOrganizationUser';
import { CreateUserModal } from '../../pages/UserSettings/components/CreateUserModal';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { DateTime } from 'luxon';
import { OrganizationUserAccess } from '../../types/organizationUsers';

const defaultTableOptions = getDefaultTableOptions<any>();

interface Props {
  hasEditPermissions: boolean;
  isParentOrg: boolean;
  org: any;
}

export const OrganizationUsersTable = ({
  hasEditPermissions,
  isParentOrg,
  org,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [currentOrgId] = useOrganizationId(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isWarnConfirmModalOpened, setIsWarnConfirmModalOpened] = useState(false);

  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof any, string>>
  >({});
  const [createUserModalOpened, setCreateUserModalOpened] = useState(false);

  const { mutateAsync: postUser, isLoading: isCreatingUser } = usePostOrganizationUser();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const validateOrganizationUser = (member: any): boolean => {
    const errors: Partial<Record<keyof any, string>> = {};
    if (!member.email) {
      errors.email = t('page.userSettings.organization.validationMsgs.email');
    }
    setValidationErrors(errors);
    return !Object.keys(errors).length;
  };

  const handleCreateUser = async (user: any) => {
    if (!validateOrganizationUser(user)) return;
    await postUser({ user });
    setCreateUserModalOpened(false);
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'isOrganizationAdmin',
        header: t(
          'page.userSettings.organization.people.table.headers.isOrganizationAdmin-short',
        ),
        mantineEditTextInputProps: {},
        Cell: ({ row }) => {
          if (row?.original?.organizationMember?.[0]?.isOrganizationAdmin) {
            return (
              <Tooltip
                label={t(
                  `page.userSettings.organization.people.table.headers.isOrganizationAdmin-tooltip${!isParentOrg ? '-child' : ''
                  }`,
                )}
              >
                <span style={{ cursor: 'help' }}>
                  <Icon color="var(--color-primary)" icon="star" />
                </span>
              </Tooltip>
            );
          } else if (row?.original?.organizationMember?.[0]?.isReadOnly) {
            return (
              <Tooltip
                label={t(
                  `page.userSettings.organization.people.table.headers.isReadOnly-tooltip`,
                )}
              >
                <span style={{ cursor: 'help' }}>
                  <Icon icon="eye" style={{
                    color: 'var(--color-gray-2)',
                    filter: 'invert(0.5)',
                  }} />
                </span>
              </Tooltip>
            );
          }
          return null;
        },
        Header: () => (
          <></>
        ),
        Filter: () => null,
        enableSorting: false,
        size: 30,
      },
      {
        accessorKey: 'firstName',
        header: t('page.userSettings.organization.people.table.headers.firstName'),
        mantineEditTextInputProps: {},
        size: 120,
      },
      {
        accessorKey: 'lastName',
        header: t('page.userSettings.organization.people.table.headers.lastName'),
        mantineEditTextInputProps: {},
        size: 120,
      },
      {
        accessorKey: 'email',
        header: t('page.userSettings.organization.people.table.headers.email'),
        mantineEditTextInputProps: {},
        enableEditing: false,
      },
      {
        accessorKey: 'title',
        header: t('page.userSettings.organization.people.table.headers.title'),
        mantineEditTextInputProps: {},
        size: 120,
      },
      {
        accessorFn: (row) => new Date(row.lastLogin),
        header: t('page.userSettings.organization.people.table.headers.lastLogin'),
        mantineEditTextInputProps: {},
        enableColumnFilter: false,
        enableEditing: false,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue<string>();
          let dateObject = DateTime.fromISO(cellValue);

          if (!dateObject.isValid) {
            dateObject = DateTime.fromJSDate(new Date(cellValue));
          }

          if (dateObject.isValid) {
            const date = dateObject.toLocaleString(DateTime.DATETIME_MED);
            return (
              <Tooltip label={dateObject.toRelative()}>
                <span>{date}</span>
              </Tooltip>
            );
          } else {
            return <span>{t('global.invalidDate')}</span>;
          }
        },
        size: 120,
      },
    ],
    [validationErrors],
  );

  const {
    data: fetchedOrganizationUsers = [],
    isLoading: isLoadingOrganizationUsers,
    isFetching: isFetchingOrganizationUsers,
  } = useFetchOrganizationUsers({ refetchOnWindowFocus: false });

  const { isLoading: isCreatingOrganizationUser } = usePostOrganizationUser();
  const { mutateAsync: putOrganizationUser, isLoading: isUpdatingOrganizationUser } =
    usePutOrganizationUser();
  const { mutateAsync: deleteOrganizationUser, isLoading: isDeletingOrganizationUser } =
    useDeleteOrganizationUser();

  const handleUpdateOrganizationUser: MRT_TableOptions<any>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    const thisUser = {
      ...row.original,
      ...values,
    } as any;
    if (!validateOrganizationUser(thisUser)) return;
    await putOrganizationUser({ user: thisUser });
    exitEditingMode();
  };

  const openDeleteConfirmModal = (row: MRT_Row<any>) =>
    modals.openConfirmModal({
      title: t('page.userSettings.organization.confirmDeleteModal.title'),
      children: (
        <Text>
          {t('page.userSettings.organization.confirmDeleteModal.body', {
            email: row.original.email,
          })}
        </Text>
      ),
      labels: { confirm: t('global.delete'), cancel: t('global.cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        deleteOrganizationUser({
          memberId: row.original.organizationMember?.[0]?._id?.toString()!,
        });
      },
      onCancel: () => {
        setIsEditing(false);
        setIsWarnConfirmModalOpened(false);
      },
    });

  const table = useMantineReactTable({
    ...defaultTableOptions,
    columns,
    data: fetchedOrganizationUsers,
    getRowId: (row) => row._id?.toString(),
    enableFacetedValues: true,
    enableEditing: hasEditPermissions,
    enableRowActions: hasEditPermissions,
    onEditingRowSave: handleUpdateOrganizationUser,
    initialState: {
      ...defaultTableOptions.initialState,
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [{ id: 'email', desc: false }],
    },
    state: {
      isLoading: isLoadingOrganizationUsers,
      isSaving: isUpdatingOrganizationUser,
      showLoadingOverlay:
        isFetchingOrganizationUsers ||
        isUpdatingOrganizationUser ||
        isDeletingOrganizationUser ||
        isCreatingOrganizationUser,
    },
    renderTopToolbarCustomActions: () => (
      <>
        {hasEditPermissions && (
          <Button
            leftIcon={<Icon icon="plus" />}
            onClick={() => setCreateUserModalOpened(true)}
          >
            {t('page.userSettings.organization.people.add-user')}
          </Button>
        )}
      </>
    ),
    renderRowActions: hasEditPermissions
      ? ({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Tooltip label={t('global.edit')}>
            <ActionIcon
              color="gray"
              onClick={() => {
                setIsEditing(true);
                table.setEditingRow(row);
              }}
            >
              <Icon icon="edit" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('global.delete')}>
            <ActionIcon color="gray" onClick={() => openDeleteConfirmModal(row)}>
              <Icon icon="trash" />
            </ActionIcon>
          </Tooltip>
        </Box>
      )
      : undefined,
    mantineEditRowModalProps: {
      opened: isEditing && !isWarnConfirmModalOpened,
      onClose: () => {
        table?.setEditingRow(null);
        setIsEditing(false);
        setIsWarnConfirmModalOpened(false);
      },
      title: t('page.userSettings.organization.people.table.headers.edit-user'),
    },
    renderEditRowModalContent: ({ row, table }) => {
      const [editingOrgUser, setEditingOrgUser] = useState<{
        firstName?: string;
        lastName?: string;
        title?: string;
        isOrganizationAdmin?: boolean;
        isReadOnly?: boolean;
      }>({
        firstName: row?.original?.firstName,
        lastName: row?.original?.lastName,
        title: row?.original?.title,
        isOrganizationAdmin: row?.original?.organizationMember?.[0]?.isOrganizationAdmin,
        isReadOnly: row?.original?.organizationMember?.[0]?.isReadOnly,
      });

      const thisUser = {
        ...row.original,
      } as any;

      const openUpdateConfirmModal = () => {
        modals.openConfirmModal({
          title: (
            <b>
              {t('page.userSettings.organization.people.table.headers.add-external-warn')}
            </b>
          ),
          children: (
            <Stack>
              <Text>
                {t('page.userSettings.organization.people.table.warn-update-external', {
                  email: row?.original?.email,
                })}
              </Text>
              <Text>
                <b>
                  {t('page.userSettings.organization.people.table.warn-are-you-sure')}
                </b>
              </Text>
            </Stack>
          ),
          labels: {
            confirm: t(
              'page.userSettings.organization.people.table.confirm-warn-update-external',
            ),
            cancel: t('global.cancel'),
          },
          onConfirm: () => {
            onConfirm();
            setIsWarnConfirmModalOpened(false);
          },
          onCancel: () => {
            setIsWarnConfirmModalOpened(false);
          },
        });
      };

      const onConfirm = () => {
        handleUpdateOrganizationUser({
          exitEditingMode: () => {
            table?.setEditingRow(null);
          },
          row,
          table,
          values: {
            ...editingOrgUser,
          },
        });
        setEditingOrgUser({});
        setIsEditing(false);
      };

      const isExternal =
        row?.original?.email &&
        org.domains &&
        !org?.domains?.includes(row.original.email.split('@')[1]);

      const getNormalizedAccessType = (): OrganizationUserAccess => {
        const isReadOnly = editingOrgUser.isReadOnly;
        const isOrganizationAdmin = editingOrgUser.isOrganizationAdmin;

        if (isOrganizationAdmin) {
          return 'admin';
        }
        if (isReadOnly) {
          return 'readonly';
        }
        return 'standard';
      }

      // Return a normalized access type
      const setNormalizedAccessType = (val: OrganizationUserAccess) => {
        console.info('Setting normalized access type to:', val);
        switch (val) {
          case 'readonly':
            setEditingOrgUser({
              ...editingOrgUser,
              isReadOnly: true,
              isOrganizationAdmin: false,
            })
            break;
          case 'admin':
            setEditingOrgUser({
              ...editingOrgUser,
              isReadOnly: false,
              isOrganizationAdmin: true,
            })
            break;
          default:
            setEditingOrgUser({
              ...editingOrgUser,
              isReadOnly: false,
              isOrganizationAdmin: false,
            })
        }
      }


      return (
        <Stack>
          <Collapse in={isExternal}>
            <Alert icon={<Icon icon="circle-info" />} color="yellow">
              {t('page.userSettings.organization.people.table.headers.editing-external')}
            </Alert>
          </Collapse>
          <Flex justify="space-between">
            <TextInput
              label={t('page.userSettings.organization.people.table.headers.firstName')}
              required
              value={editingOrgUser.firstName}
              onChange={(event) =>
                setEditingOrgUser({
                  ...editingOrgUser,
                  firstName: event.currentTarget.value,
                })
              }
              error={validationErrors.firstName}
              style={{ width: '48%' }}
              aria-userId={row?.original?._id}
              aria-memberId={row?.original?.organizationMember?.[0]?._id?.toString()}
            />
            <TextInput
              label={t('page.userSettings.organization.people.table.headers.lastName')}
              required
              value={editingOrgUser.lastName}
              onChange={(event) =>
                setEditingOrgUser({
                  ...editingOrgUser,
                  lastName: event.currentTarget.value,
                })
              }
              style={{ width: '48%' }}
              error={validationErrors.lastName}
            />
          </Flex>
          <TextInput
            label={t('page.userSettings.organization.people.table.headers.email')}
            disabled
            value={row?.original?.email}
          />
          <TextInput
            label={t('page.userSettings.organization.people.table.headers.title')}
            value={editingOrgUser.title}
            onChange={(event) =>
              setEditingOrgUser({
                ...editingOrgUser,
                title: event.currentTarget.value,
              })
            }
            error={validationErrors.title}
          />
          <Radio.Group
            name="roleSelection"
            label={t('page.userSettings.organization.people.table.headers.accessType')}
            value={getNormalizedAccessType()}
            onChange={(value) => setNormalizedAccessType(value as OrganizationUserAccess)}
          >
            <Stack mt="xs" style={{
              gap: '10px',
            }}>
              <Radio size='sm' value="standard" label={t('page.userSettings.organization.people.table.headers.isStandard')} />
              <Flex align="center">
                <Radio size='sm' value="admin" label={t('page.userSettings.organization.people.table.headers.isOrganizationAdmin')} style={{
                  display: 'inline-block',
                }} />
                <Text size="sm" color="gray" style={{
                  marginLeft: '5px',
                  position: 'relative',
                }}>- {t('page.userSettings.organization.people.table.headers.isOrganizationAdmin-description')}</Text>
              </Flex>
              <Flex align="center" style={{
                marginBottom: '0px',
                paddingBottom: '0px'
              }}>
                <Radio size='sm' value="readonly" label={t('page.userSettings.organization.people.table.headers.isReadOnly')} style={{
                  display: 'inline-block',
                }} />
                <Text size="sm" color="gray" style={{
                  marginLeft: '5px',
                  position: 'relative',
                }}>- {t('page.userSettings.organization.people.table.headers.isReadOnly-description')}</Text>
              </Flex>
            </Stack>
          </Radio.Group>
          <Flex justify="flex-end" gap="md">
            <Button
              type="button"
              onClick={() => {
                table?.setEditingRow(null);
                setEditingOrgUser({});
                setIsEditing(false);
                setIsWarnConfirmModalOpened(false);
              }}
              color="dark"
            >
              {t('global.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={() => {
                if (!isExternal) {
                  onConfirm();
                } else {
                  setIsWarnConfirmModalOpened(true);
                  openUpdateConfirmModal();
                }
              }}
            >
              {t('page.userSettings.organization.people.table.headers.edit-user-confirm')}
            </Button>
          </Flex>
        </Stack>
      );
    },
  });

  return (
    <>
      <MantineReactTable table={table} />
      <CreateUserModal
        isCreating={isCreatingUser}
        opened={createUserModalOpened}
        onCancel={() => setCreateUserModalOpened(false)}
        onConfirm={handleCreateUser}
        validationErrors={validationErrors}
        isParentOrg={isParentOrg}
        org={fetchedCurrentOrganization}
      />
    </>
  );
};
