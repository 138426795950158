import type { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface Params {
  organizationAssetId?: string;
  enabled?: boolean;
  limit?: number;
  organizationId?: string;
  page?: number;
  sortColumn?: string;
  sortType?: string;
  search?: string;
}

export const useFetchAssetVersions = ({
  organizationAssetId,
  enabled = true,
  limit,
  page,
  sortColumn = 'dateCreated',
  sortType = 'desc',
  search = '',
}: Params) => {
  let fetchUrl = `asset/${organizationAssetId}/versions`;
  fetchUrl += '?';
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (search && search?.length > 0) {
    fetchUrl += `&search=${search}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":"${sortType === 'asc' ? 1 : -1}","_id":"${
      sortType === 'asc' ? 1 : -1
    }"}`;
  }
  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: [
      'assetVersions',
      organizationAssetId && { organizationAssetId },
      `/${fetchUrl}`,
    ].filter(Boolean),
    queryFn: () => hitApi.get(fetchUrl),
    select: (data: {
      data: Partial<InterfaceOrganizationAsset>[];
      queryInfo: { countVersions: number; nPage: number; nLimit: number };
    }) => {
      return {
        ...data,
        data: data.data.filter((asset) => asset?._id),
      };
    },
    onError: (error) => {
      captureExceptionWithMessage(
        `Unable to fetch versions for asset ${organizationAssetId}: `,
        error,
      );
    },
  });
};
