import { InterfaceOrganizationIntegration } from '@manifest-cyber/types/interface/dbTables';
import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { hasLength, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';
import hitApi from '../../api';
import {
  CreateTicketFormValues,
  usePostTicket,
} from '../../hooks/mutations/usePostTicket';
import { useAuth } from '../../hooks/useAuth';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import Icon from '../Icon';
import './ticket.scss';
const SERVICENOW_LOGO_SRC = '/servicenow_logo.png';

interface SelectData {
  value: string;
  label: string;
}
interface Props {
  open: boolean;
  entityType: 'asset' | 'vulnerability';
  entityId: string;
  onSuccess: (
    integration: NonNullable<InterfaceOrganizationIntegration['integrationType']>,
    ticketData: any,
  ) => void;
  onError: (integration: Parameters<this['onSuccess']>[0]) => void;
  onCancel: () => void;
}
function CreateServicenowTicket({
  open,
  entityType,
  entityId,
  onSuccess,
  onError,
  onCancel,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [creators, setCreators] = useState<SelectData[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [issueTypes, setIssueTypes] = useState<SelectData[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<SelectData[]>([]);
  const [priorities, setPriorities] = useState<string[]>([]);
  const auth = useAuth();

  const user = auth?.user;

  const { mutateAsync: postTicket, isLoading: isCreatingTicket } = usePostTicket();

  const form = useForm({
    initialValues: {
      creator: '',
      issueType: '',
      status: '',
      summary: '',
      description: '',
      assignee: '',
      priority: '',
    },
    validate: {
      summary: hasLength({ max: 255 }, 'Summary cannot be longer than 255 characters'),
      description: hasLength(
        { max: 32767 },
        'Description cannot be longer than 32,767 characters',
      ),
    },
  });
  useEffect(() => {
    getTicketMetadata();
  }, []);

  useEffect(() => {
    trySetCreator();
  }, [users]);

  useEffect(() => {
    const { creator, issueType, summary } = form.values;
    if (!issueType || !summary || !creator || !assignees.length) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [form.values]);

  const handleCreateTicket = async () => {
    try {
      setIsLoading(true);
      const submitValues: CreateTicketFormValues = {
        issueType: form.values.issueType,
        status: form.values.status,
        summary: form.values.summary,
        description: form.values.description,
        assigneeIds: [form.values.assignee],
        priority: form.values.priority,
        creatorId: form.values.creator,
        type: entityType,
        id: entityId,
        integration: 'servicenow',
      };

      const { success, data } = await postTicket({ ticket: submitValues });
      if (!success) {
        throw new Error('error while trying to create servicenow ticket');
      }
      return onSuccess('servicenow', data);
    } catch (e) {
      return onError('servicenow');
    } finally {
      setIsLoading(false);
      form.reset();
    }
  };
  const convertToSelectData = (data: any[]) => {
    return data.map((item: any) => ({ value: item.id, label: item.name }));
  };

  const trySetCreator = () => {
    if (!user) return null;
    const [email] = user?.decryptedEmails;
    const currentUser = users.find((user: any) => user.email === email);
    if (currentUser) {
      form.setValues({ creator: currentUser.id });
      return currentUser.id;
    }
    return null;
  };

  const getTicketMetadata = async () => {
    try {
      setIsLoading(true);
      const { success, data } = await hitApi.get(
        '/integration/merge/ticket-metadata?integration=servicenow',
      );
      if (!success) {
        throw new Error('error while trying to fetch servicenow form options');
      }
      const { ticketTypes, statuses, priorities, users } = data;
      const creatorsData = convertToSelectData(users);
      const assigneesData = convertToSelectData(users);
      const issueTypesSelectData = convertToSelectData(ticketTypes);

      //const creators = users
      //const { projects, issueTypes, statuses, assignees, priorities, labels } = sample
      setCreators(creatorsData);
      setIssueTypes(issueTypesSelectData);
      setStatuses(statuses);
      setAssignees(assigneesData);
      setPriorities(priorities);
      setUsers(users);
    } catch (e) {
      captureExceptionWithMessage('error get ticket metadata', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (isCreatingTicket) {
      return;
    }

    onCancel();
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className="create-ticket-modal">
      <LoadingOverlay visible={isLoading} overlayBlur={8} />
      <Modal.Header>
        <Modal.Title>
          <div className="header">
            <div className="logo">
              <img src={SERVICENOW_LOGO_SRC} />
            </div>
            {t(`tickets.createServicenowTicketModal.header`)}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box mx="auto">
          <form id="create-servicenowticket" onSubmit={form.onSubmit(handleCreateTicket)}>
            <TextInput
              mb={'lg'}
              required
              label={t(`tickets.createServicenowTicketModal.form.summary`)}
              {...form.getInputProps('summary')}
            />
            <Group grow mb={'lg'} spacing={40}>
              <Select
                label={t(`tickets.createServicenowTicketModal.form.issueType`)}
                data={issueTypes}
                searchable
                required
                nothingFound={t(`tickets.createServicenowTicketModal.form.noResults`)}
                {...form.getInputProps('issueType')}
              />
              <Select
                label={t(`tickets.createServicenowTicketModal.form.status`)}
                data={statuses}
                searchable
                nothingFound={t(`tickets.createServicenowTicketModal.form.noResults`)}
                {...form.getInputProps('status')}
              />
            </Group>
            <Group grow spacing={40}>
              <Select
                mb={'lg'}
                label={t(`tickets.createServicenowTicketModal.form.creator`)}
                data={creators}
                searchable
                required
                nothingFound={t(`tickets.createServicenowTicketModal.form.noResults`)}
                {...form.getInputProps('creator')}
              />
              <Select
                mb={'lg'}
                label={t(`tickets.createServicenowTicketModal.form.assignee`)}
                data={assignees}
                searchable
                required
                nothingFound={t(`tickets.createServicenowTicketModal.form.noResults`)}
                rightSection={<Icon icon="chevron-down" />}
                {...form.getInputProps('assignee')}
              />
            </Group>
            <Select
              mb={'lg'}
              label={t(`tickets.createServicenowTicketModal.form.priority`)}
              data={priorities}
              searchable
              nothingFound={t(`tickets.createServicenowTicketModal.form.noResults`)}
              {...form.getInputProps('priority')}
            />
            <Textarea
              mb={'lg'}
              label={t(`tickets.createServicenowTicketModal.form.description`)}
              {...form.getInputProps('description')}
            />
          </form>
        </Box>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button variant="default" onClick={onCancel} disabled={isCreatingTicket}>
          {t(`global.cancel`)}
        </Button>
        <Button
          type="submit"
          form="create-servicenowticket"
          disabled={submitDisabled}
          loading={isCreatingTicket}
        >
          {t('global.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateServicenowTicket;
