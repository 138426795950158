// import { useAuth } from '../../hooks/useAuth';
import { useLocalStorage } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../components/ClickableRegion';
import Icon from '../components/Icon';
import { captureExceptionWithMessage } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../scss/pages/noMatch.scss';

export const NoMatch404 = () => {
  // const { user } = useAuth();
  const { t } = useTranslation();
  const [photoToDisplay, setPhotoToDisplay] = useState<string | null>(null);
  const [seenPhotos, setSeenPhotos] = useLocalStorage({
    key: 'manifest-404-photos',
    defaultValue: '[]',
  });

  const availablePhotos = [
    'amy',
    'andrew',
    'cody',
    'marc',
    'marcelo',
    'mike',
    'tiff',
    'came',
  ];

  const chooseRandomNewString = (): string | null => {
    const randomIndex = Math.floor(Math.random() * availablePhotos.length);
    return availablePhotos[randomIndex] || null;
  };

  useEffect(() => {
    try {
      if (!photoToDisplay) {
        let jsonPhotos = JSON.parse(seenPhotos);
        if (jsonPhotos.length === availablePhotos.length) {
          jsonPhotos = []; // Reset if all photos have been seen.
        }
        setPhotoToDisplay(chooseRandomNewString() || null);
      }
    } catch (err) {
      captureExceptionWithMessage('photoToDisplay error:', err);
    }
  }, []);

  return (
    <section className="page-noMatch">
      <div className="header-404">
        <span className="left anim-slideInLeftShort">4</span>
        <div className="picture-cont anim-slideInDownShort">
          <img
            src={photoToDisplay ? `/team-404/${photoToDisplay}.jpg` : ''}
            alt="404"
            width="300"
            height="300"
          />
        </div>
        <span className="right anim-slideInRightShort">4</span>
      </div>

      <div className="oops anim-slideInUpShort">
        <h2>{t('page.no-match.header')}</h2>
        <div className="divider" />
        <div className="description">
          <p>{t('page.no-match.details')}</p>
        </div>
      </div>

      <div className="go-back anim-slideInUpShort">
        <ClickableRegion
          regionLabel="Click to go back!"
          href={'/'}
          className="its-awesome"
        >
          <Icon icon="angle-left" />
          <span>{t('page.no-match.return')}</span>
        </ClickableRegion>
      </div>
    </section>
  );
};

export default NoMatch404;
