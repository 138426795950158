import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import {
  InterfaceOrganizationCustomField,
  OrganizationCustomField,
} from '@manifest-cyber/types/interface/dbTables';
import hitApi from '..';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { CustomField } from './pageConfig.interface';

const createCustomField = async (params: { body: CustomField }) => {
  const response: ManifestApiResponse<
    ManifestApiResponse<InterfaceOrganizationCustomField>
  > = await hitApi.post(`organization/customfields`, true, params.body);

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return successfulResponse.data;
};

const getCustomFields = async (params: {
  fieldType: OrganizationCustomField;
}): Promise<string[]> => {
  const response: ManifestApiResponse<string[]> = await hitApi.get(
    `organization/customfields/${params.fieldType}`,
  );

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return successfulResponse.data;
};

export const CustomFieldsApi = {
  createCustomField,
  getCustomFields,
};
