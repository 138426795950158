import { SbomEnrichmentSource } from '@manifest-cyber/types/interface/db';
import { InterfaceOrganization } from '@manifest-cyber/types/interface/dbTables';
import { Divider, Flex, Switch, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Loading';
import { usePostOrganization } from '../../../hooks/mutations/usePostOrganization';
import { useFetchOrganization } from '../../../hooks/queries/useFetchOrganization';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import styles from './UploadSettings.module.scss';

export const UploadSettings = () => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(null);
  const { error } = useNotifications();

  const {
    data: fetchedCurrentOrganization,
    isLoading: isLoadingCurrentOrganization,
    isError: isErrorLoadingCurrentOrganization,
  } = useFetchOrganization({ organizationId: currentOrgId });

  const mutation = usePostOrganization();

  const toggleEnrichment = () => {
    try {
      if (!fetchedCurrentOrganization) throw new Error('Organization data not available');

      const updatedOrg: InterfaceOrganization = {
        ...fetchedCurrentOrganization,
        defaultSettings: {
          ...fetchedCurrentOrganization.defaultSettings,
          enabledSbomEnrichmentSource:
            fetchedCurrentOrganization.defaultSettings?.enabledSbomEnrichmentSource ===
            'PARLAY'
              ? undefined
              : 'PARLAY',
        } as { enabledSbomEnrichmentSource: SbomEnrichmentSource | undefined },
      };

      mutation.mutate(
        { organization: updatedOrg, isChild: false },
        {
          onError: () => {
            error({
              title: t('global.error'),
              message: t('page.uploadSettings.errors.updateSBOMEnrichmentFailed'),
            });
          },
        },
      );
    } catch (err) {
      captureExceptionWithMessage('toggleEnrichment', err);
    }
  };

  if (isLoadingCurrentOrganization) {
    return <Loading />;
  }

  if (isErrorLoadingCurrentOrganization) {
    error({
      title: t('global.error'),
      message: t('page.uploadSettings.errors.fetchOrganizationDataFailed'),
    });
  }

  return (
    <div className="anim-slideInDownShort">
      <Flex justify="space-between" align="flex-start" direction="column">
        <Title order={2} mb={10}>
          {t('page.uploadSettings.title')}
        </Title>
        <Text className={styles.subtitle}>{t('page.uploadSettings.subtitle')}</Text>
      </Flex>
      <Divider my={24} className={styles.headerDivider} />
      <Flex direction="column" gap={12}>
        <Switch
          classNames={{
            label: styles.switchLabel,
          }}
          label={t('page.uploadSettings.enrichByDefault')}
          labelPosition="left"
          checked={
            fetchedCurrentOrganization?.defaultSettings?.enabledSbomEnrichmentSource ===
            'PARLAY'
          }
          onChange={toggleEnrichment}
          disabled={mutation.isLoading}
        />
        <Text className={styles.fieldDescription}>
          {t('page.uploadSettings.enrichByDefaultDescription')}
        </Text>
      </Flex>
    </div>
  );
};
