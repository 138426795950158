import hitApi from '..';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { InterfaceFullUser, UserApiResponse } from '../../types/FullInterfaces';
import { mapUser } from './user.mapper';

export interface MappedUser extends InterfaceFullUser {
  hasOrganizations: boolean;
  id: string;
  isPaidOrgs: boolean;
}

const getUser = async (): Promise<MappedUser> => {
  const response: UserApiResponse = await hitApi.get('user');

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);
  const user = successfulResponse.data?.[0];

  if (!user) {
    throw new Error('No user found');
  }

  return mapUser(user);
};

export const UserApi = {
  getUser,
};
