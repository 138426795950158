import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTriggerHandle } from 'rsuite/esm/internals/Picker';
import { useFetchSharingPortal } from '../../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import Icon from '../../../components/Icon';
import { useSBOMsUtils } from '../../../hooks/utils/useSBOMsUtils';
import MergeDownloadModal from '../mergeDownload';
import MergeModal from '../mergeModal';
import { SBOMSelectionData } from '../models/SBOMSelectionData.model';
import ShareModal from '../ShareModal/shareModal';
import { MenuButton } from './MenuButton';
import { MenuButtonItem } from './MenuButtonItem';

interface Props {
  SBOMs: SBOMSelectionData[];
  loading?: boolean;
  selectedAssets: string[];
  handleAssetProductAssociationCheck: (
    selectedAssetIds: (string | undefined)[],
    action: 'active' | 'inactive',
  ) => void;
  openShareToPortalModal: () => void;
  hasWriteAccess: boolean;
}

export const AssetsActions = (props: Props) => {
  const {
    SBOMs,
    loading,
    selectedAssets,
    handleAssetProductAssociationCheck,
    hasWriteAccess,
  } = props;
  const [cantMergeShareReason, setCantMergeShareReason] = useState('');
  const [cantMergeDownloadReason, setCantMergeDownloadReason] = useState('');
  const [isMergeDownloadModalOpen, setMergeDownloadModal] = useState(false);
  const [isShareModalOpen, setShareModal] = useState([false, false]);
  const [isMergeModalOpen, setMergeModal] = useState(false);
  const [mergeResultSBOMId, setMergeResultSBOMId] = useState('');

  const { t } = useTranslation();
  const { downloadSBOMs, mergeSBOMs } = useSBOMsUtils();
  const { data: sharingPortal } = useFetchSharingPortal();
  const isEnabledSharingPortal = Boolean(sharingPortal?.isActive);
  const mergeAndShareMenuRef = useRef<OverlayTriggerHandle>(null);
  const mergeAndDownloadMenuRef = useRef<OverlayTriggerHandle>(null);

  // memos
  const selectedSBOMIds = useMemo(() => {
    return SBOMs.map((s) => s.id);
  }, [SBOMs]);

  const selectedSBOMFormats = useMemo(() => {
    return new Set(SBOMs.map((sd) => sd.format));
  }, [SBOMs]);

  const selectedSBOMTypes = useMemo(() => {
    return new Set(SBOMs.map((sd) => sd.fileType));
  }, [SBOMs]);

  //handlers
  const handleMergeSBOMsAndDownload = () => {
    mergeAndDownloadMenuRef?.current?.close();
    setMergeDownloadModal(true);
  };

  const handleShareSBOMs = (fromMerge: boolean = false) => {
    mergeAndShareMenuRef?.current?.close();
    setShareModal([true, fromMerge]);
  };

  const handleMergeSBOMsAndShare = async () => {
    setMergeModal(true);
    const result = await mergeSBOMs(selectedSBOMIds);
    if (result) {
      setMergeResultSBOMId(result.sbomId);
      setMergeModal(false);
      handleShareSBOMs(true);
    }
  };

  //effects
  useEffect(() => {
    if (SBOMs.length <= 1) {
      // single selection return "multiple selection" error
      setCantMergeDownloadReason(
        t('component.shareDownloadOptionButton.errors.please-select-multiple-sboms'),
      );
      setCantMergeShareReason(
        t('component.shareDownloadOptionButton.errors.please-select-multiple-sboms'),
      );
      if (selectedSBOMTypes.has('VEX')) {
        // VEX file never can ve merge, do not care if it is a single selection
        // return "cannot merge VEX file" error
        setCantMergeDownloadReason(
          t('component.shareDownloadOptionButton.errors.vex-cannot-be-merge'),
        );
      }
      return;
    }
    // multiple selection
    if (selectedSBOMTypes.has('VEX')) {
      // if selection contains VEX file, return "cannot merge VEX file" error
      setCantMergeDownloadReason(
        t('component.shareDownloadOptionButton.errors.vex-cannot-be-merge'),
      );
      if (selectedSBOMTypes.has('SBOM')) {
        // if selection has a SBOM & VEX, return "cannot merge SBOM & VEX files" error
        setCantMergeDownloadReason(
          t('component.shareDownloadOptionButton.errors.vex-sbom-cannot-be-merge'),
        );
      }
      return;
    }

    //multiple selection & same type
    if (selectedSBOMFormats.size > 1) {
      // if selection contains multiple format of the same type, return "SBOMs must be the same format" error
      setCantMergeDownloadReason(
        t(`component.shareDownloadOptionButton.errors.sboms-must-be-same-type`),
      );
      setCantMergeShareReason(
        t(`component.shareDownloadOptionButton.errors.sboms-must-be-same-type`),
      );
      return;
    }

    //selection is ok
    setCantMergeDownloadReason('');
    setCantMergeShareReason('');
  }, [SBOMs, selectedSBOMTypes, selectedSBOMFormats]);

  return (
    <>
      <MenuButton
        selectionCount={0}
        loading={loading}
        text={
          loading ? (
            ''
          ) : (
            <Icon
              icon="ellipsis-vertical"
              style={{ display: 'flex' }}
              aria-label={t('action.moreOptions')}
            />
          )
        }
        ariaLabel={t('component.shareDownloadOptionButton.actions')}
        showArrow={false}
        showSelectionCount={false}
      >
        <MenuButtonItem
          disabled={SBOMs.length < 1}
          disabledText={t(
            'component.shareDownloadOptionButton.errors.please-select-a-sbom-download',
          )}
          icon="arrow-down-to-bracket"
          text={t('component.shareDownloadOptionButton.download-sboms')}
          onClick={() => downloadSBOMs(selectedSBOMIds)}
        />
        {hasWriteAccess && (
          <MenuButtonItem
            disabled={!!cantMergeDownloadReason}
            disabledText={cantMergeDownloadReason || ''}
            icon="merge"
            text={t('component.shareDownloadOptionButton.merge-download-sboms')}
            onClick={handleMergeSBOMsAndDownload}
          />
        )}

        {isEnabledSharingPortal && hasWriteAccess && (
          <MenuButtonItem
            disabled={SBOMs.length < 1}
            disabledText={t(
              'component.shareDownloadOptionButton.errors.please-select-a-sbom-share',
            )}
            icon="share-nodes"
            text={t('component.shareDownloadOptionButton.share-sboms')}
            onClick={() => props.openShareToPortalModal()}
          />
        )}
        {!isEnabledSharingPortal && hasWriteAccess && (
          <>
            <MenuButtonItem
              // cannot share VEX files
              disabled={SBOMs.length < 1 || selectedSBOMTypes.has('VEX')}
              disabledText={
                selectedSBOMTypes.has('VEX')
                  ? ''
                  : t(
                      'component.shareDownloadOptionButton.errors.please-select-a-sbom-share',
                    )
              }
              icon="share-nodes"
              text={t('component.shareDownloadOptionButton.share-sboms')}
              onClick={() => handleShareSBOMs(false)}
            />
            <MenuButtonItem
              // cannot share VEX files
              disabled={
                !!cantMergeShareReason || selectedSBOMTypes.has('VEX') || !hasWriteAccess
              }
              disabledText={
                selectedSBOMTypes.has('VEX') ? '' : cantMergeShareReason || ''
              }
              icon="merge"
              text={t('component.shareDownloadOptionButton.merge-share-sboms')}
              onClick={handleMergeSBOMsAndShare}
            />
          </>
        )}
        {hasWriteAccess && (
          <MenuButtonItem
            disabled={selectedAssets.length < 1}
            disabledText={t('component.shareDownloadOptionButton.selectAtLeastOneAsset')}
            icon="eye"
            text={t('component.shareDownloadOptionButton.markAsActive')}
            onClick={() => handleAssetProductAssociationCheck(selectedAssets, 'active')}
          />
        )}
        {hasWriteAccess && (
          <MenuButtonItem
            disabled={selectedAssets.length < 1}
            disabledText={t('component.shareDownloadOptionButton.selectAtLeastOneAsset')}
            icon="eye-slash"
            text={t('component.shareDownloadOptionButton.markAsInactive')}
            onClick={() => handleAssetProductAssociationCheck(selectedAssets, 'inactive')}
          />
        )}
      </MenuButton>
      {hasWriteAccess && (
        <>
          <MergeDownloadModal
            open={isMergeDownloadModalOpen}
            sbomIds={selectedSBOMIds}
            onClose={() => {
              setMergeDownloadModal(false);
            }}
            downloadFn={downloadSBOMs}
          />
          <MergeModal open={isMergeModalOpen} onClose={() => setMergeModal(false)} />

          <ShareModal
            open={Boolean(isShareModalOpen[0])}
            sbomIds={isShareModalOpen[1] ? [mergeResultSBOMId] : selectedSBOMIds}
            triggeredByMerge={Boolean(isShareModalOpen[1])}
            onClose={() => setShareModal([false, false])}
          />
        </>
      )}
    </>
  );
};
