import { InterfaceOrganizationSlaSetting } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

type Params = {
  enabled?: boolean;
};

type FetchError = Error;

type Response = {
  success: boolean;
  data: InterfaceOrganizationSlaSetting;
  errors: string[];
};

export const useFetchSLAs = ({ enabled = true }: Params) => {
  let fetchUrl = `sla/setting`;

  const queryKey = [`slas`];

  return useQuery<Response, FetchError>({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: async () => {
      return hitApi.get(fetchUrl).then((response) => ({
        success: response.success,
        data: response.data[0],
        errors: response.errors,
      }));
    },
    onError: (error: FetchError) => {
      captureExceptionWithMessage('Unable to fetch product: ', error);
    },
  });
};
