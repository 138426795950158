import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import {
  GetVulnImpactApiResponse,
  sanitizeQueryParamProperty,
} from './useFetchVulnImpact';

export const useFetchVulnProducts = (
  vulnerabilityId: string,
  searchFilter: string,
  productRelationshipFilter: string,
  { pageIndex, pageSize }: PaginationState,
  sorting: SortingState,
) => {
  const params = new URLSearchParams();

  if (sorting && sorting?.[0]?.id) {
    params.set(
      'sort',
      `{${sorting
        .map((sortObj) => {
          return `"${sanitizeQueryParamProperty(sortObj.id)}":"${sortObj.desc ? 1 : -1}"`;
        })
        .join('')}}`,
    );
  }

  params.set('page', (pageIndex + 1).toString());
  params.set('limit', pageSize.toString());

  if (searchFilter) {
    params.set('search', searchFilter);
  }

  if (productRelationshipFilter && productRelationshipFilter !== 'all') {
    params.set('relationship', productRelationshipFilter);
  }

  let fetchUrl = `vulnerability/${vulnerabilityId}/products?${params.toString()}`;

  return useQuery({
    queryKey: [
      'vulnImpactProducts',
      vulnerabilityId,
      searchFilter,
      productRelationshipFilter,
      pageIndex,
      pageSize,
      sorting,
    ],
    queryFn: ({ signal }) => hitApi.get(fetchUrl, true, undefined, undefined, signal),
    select: (data) => data as GetVulnImpactApiResponse<InterfaceOrganizationProduct>,
    onError: (error) => {
      captureExceptionWithMessage('Unable to fetch vulnerability products: ', error);
    },
    keepPreviousData: true,
  });
};
