import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export const useFetchRiskiestOrganizationComponentVulns = (
  organizationComponentId: string,
) => {
  const fetchUrl = `component/riskiestVulnerabilities/${organizationComponentId}`;

  return useQuery({
    enabled: !!organizationComponentId,
    queryKey: [
      'riskiestOrganizationComponentVulns',
      { _id: organizationComponentId },
      `/${fetchUrl}`,
    ],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as InterfaceVulnerability[],
    onError: (error) => {
      captureExceptionWithMessage(
        'Unable to fetch riskiest organization component vulnerabilities: ',
        error,
      );
    },
  });
};
