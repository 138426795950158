import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationMemberUser } from '../../types/FullInterfaces';

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const useFetchOrganizationUsers = (options?: Options) => {
  let fetchUrl = `users`;

  return useQuery({
    queryKey: ['organizationUsers', `/${fetchUrl}`],
    queryFn: () => {
      return hitApi.get(fetchUrl);
    },
    select: (data) => (data?.data ?? []) as InterfaceOrganizationMemberUser[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
