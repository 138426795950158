import { DateTime } from 'luxon';
import capitalizeWords from '../../../../../lib/capitalizeWords';
import {
  MappedSharedAsset,
  SharedSbomsApiResponse,
} from '../../types/sharedSboms.interface';

export interface MapSharedSbomsTexts {
  unknownType: string;
}

export const mapSharedSboms = (
  data: SharedSbomsApiResponse['data'],
  texts?: MapSharedSbomsTexts,
): MappedSharedAsset[] =>
  data.map(({ sharedDate, assetName, assetVersion, assetType, sbomId }) => ({
    id: sbomId,
    assetName: assetName,
    dateShared: sharedDate,
    dateSharedText:
      DateTime.fromISO(`${sharedDate}`, { zone: 'utc' }).toFormat('MMM dd, yyyy HH:mm') ||
      '',
    type: assetType ? capitalizeWords(assetType) : texts?.unknownType || '',
    assetVersion: assetVersion,
  }));
