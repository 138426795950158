import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MappedUser, UserApi } from './user.api';

export const USER_QUERY_KEY = 'manifest-user';
export const REFETCH_USER_PROFILE_INTERVAL_TIME = 1000 * 10 * 1;
export const useFetchManifestUser = (): UseQueryResult<MappedUser, unknown> => {
  return useQuery({
    refetchInterval: REFETCH_USER_PROFILE_INTERVAL_TIME,
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => UserApi.getUser(),
  });
};
