import axios, { AxiosError, ResponseType } from 'axios';
import Cookies from 'js-cookie';
import appConfig from '../configs/appConfig';
import { Logger } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export const setProtocol = (url: string) => {
  const currentProtocol = appConfig?.useSsl ? 'https:' : 'http:';
  return `${currentProtocol}${url.replace('https:', '').replace('http:', '')}`;
};

/**
 * formattedApiUrl()
 * Wrapper for any preprocessing for URL
 * @param {string} useAuth Whether we are using authenticated routes or not
 * @returns {string}
 */
export const formattedApiUrl = (): string => {
  const returnUrl = `//${appConfig.apiUrl}/v${appConfig.apiVersion}`;
  return returnUrl;
};

/**
 * getOrganizationId()
 * Grabs our JWT from localStorage (or falls back to Cookie)
 */
const getOrganizationId = () => Cookies.get('organizationid') || '';

export const hitApi = {
  /**
   * API: GET
   * Can pass query params in URL or manually via params
   * @param {string} url Relative URL for API
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} params Pass in any query params
   * @param headers
   * @param signal
   * @param options
   * @returns Promise
   */
  get: async (
    url: string,
    useAuth: boolean = true,
    params: object = {},
    headers: object = {},
    signal?: AbortSignal,
    options: { responseType?: ResponseType } = {},
  ) =>
    axios
      .get(`${formattedApiUrl()}/${url}`, {
        withCredentials: useAuth, // Send cookie
        headers: {
          organizationid: `${getOrganizationId()}`,
          ...headers,
        },
        params: {
          ...params,
        },
        signal,
        ...options,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const e = error as AxiosError;

        // If we're not already on login page, redirect to login/logout for some cases
        if (!window.location.href.includes('/login')) {
          // If we're not on login page and we're getting a 403/401, redirect to logout
          if (e?.response?.status === 403) {
            Logger.captureExceptionWithMessage(
              `Error while GET. logging user out. url: "${url}" statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}.`,
              e,
            );
            window.location.href = '/logout?reason=invalid-expired-session';
          } else if (e?.response?.status === 401) {
            const responseData = e?.response?.data;
            const isDisabledFeatureFlag =
              typeof responseData === 'string' &&
              responseData.includes('Feature Not Enabled');

            if (isDisabledFeatureFlag) {
              Logger.captureExceptionWithMessage(
                `Error while GET. Feature Not Enabled. url: "${url}" statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
                e,
              );
              return Promise.reject(e);
            }

            const redirectUrl = window.location.pathname.startsWith('/o/')
              ? '/logout'
              : '/logout?reason=invalid-expired-session';

            Logger.captureExceptionWithMessage(
              `Error while GET. logging user out. url: "${url}". statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
              e,
            );
            window.location.href = redirectUrl;

            return Promise.reject(e);
          }
        }

        if (e.code !== 'ERR_CANCELED') {
          Logger.captureExceptionWithMessage(
            `Error while GET. url: "${url}" statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
            e,
          );
        }

        return Promise.reject(e);
      }),

  /**
   * API: POST
   * @param {string} url Relative URL for API
   * @param {object} body POST body
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  post: async (url: string, useAuth: boolean = true, body: {}, options: object = {}) =>
    axios
      .post(
        `${formattedApiUrl()}/${url}`,
        {
          ...body,
        },
        {
          headers: {
            organizationid: `${getOrganizationId()}`,
          },
          withCredentials: useAuth,
          ...options,
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        Logger.captureExceptionWithMessage(
          `Error while POST. url ${url}. statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
          e,
        );
        return Promise.reject(e);
      }),
  /**
   * API: PUT
   * @param {string} url Relative URL for API
   * @param {object} body POST body
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  put: async (url: string, useAuth: boolean = true, body: {}, options: object = {}) =>
    axios
      .put(
        `${formattedApiUrl()}/${url}`,
        {
          ...body,
        },
        {
          headers: {
            organizationid: `${getOrganizationId()}`,
          },
          withCredentials: useAuth,
          ...options,
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        Logger.captureExceptionWithMessage(
          `Error while PUT. url: ${url} statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
          e,
        );
        return Promise.reject(e);
      }),
  /**
   * API: PUT Files
   * @param {string} url Relative URL for API
   * @param {FormData} formData to put
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  putFiles: async (
    url: string,
    formData: FormData,
    useAuth: boolean = true,
    options: object = {},
  ) => {
    return axios({
      method: 'PUT',
      url: `${formattedApiUrl()}/${url}`,
      data: formData,
      withCredentials: useAuth,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...options,
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        Logger.captureExceptionWithMessage(
          `Error while PUT Files. url: ${url} statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
          e,
        );
        return Promise.reject(e);
      });
  },
  /**
   * API: DELETE
   * @param {string} url Relative URL for API
   * @param {object} body POST body
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  delete: async (url: string, useAuth: boolean = true, body?: {}, options: object = {}) =>
    axios
      .delete(`${formattedApiUrl()}/${url}`, {
        headers: {
          organizationid: `${getOrganizationId()}`,
        },
        data: {
          ...body,
        },
        withCredentials: useAuth,
        ...options,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        Logger.captureExceptionWithMessage(
          `Error while DELETE url: ${url} statusCode: ${e?.response?.status} browserPathname: ${window.location.pathname}`,
          e,
        );
        return Promise.reject(e);
      }),
};

export default hitApi;
