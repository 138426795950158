import { Button, Flex } from '@mantine/core';
import Icon from '../../../../../components/Icon';

interface TableToolbarProps {
  onClick: () => void;
  label: string;
  showButton: boolean;
  isLoadingButton?: boolean;
}

export const TableToolbar = ({
  onClick,
  showButton,
  label,
  isLoadingButton,
}: TableToolbarProps) => {
  return (
    <Flex justify="flex-end" m="1rem 0">
      {showButton && (
        <Button
          variant="filled"
          onClick={onClick}
          loading={isLoadingButton}
          leftIcon={<Icon icon="arrow-down-to-bracket" />}
        >
          {label}
        </Button>
      )}
    </Flex>
  );
};
