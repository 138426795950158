import { InterfaceFullUser } from '../../types/FullInterfaces';
import { MappedUser } from './user.api';

export const mapUser = (user: InterfaceFullUser): MappedUser => {
  return {
    ...user,
    hasOrganizations: user.organizations.length > 0,
    id: user._id,
  };
};
