import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';

import { useDeleteOrganization } from '../../hooks/mutations/useDeleteOrganization';
import { usePostOrganization } from '../../hooks/mutations/usePostOrganization';
import { usePostOrganizationUser } from '../../hooks/mutations/usePostOrganizationUser';
import { useFetchChildOrganizations } from '../../hooks/queries/useFetchChildOrganizations';
import representOrganization from '../../lib/representOrganization';
import ClickableRegion from '../ClickableRegion';
import './SubOrganizationsTable.scss';

const defaultTableOptions = getDefaultTableOptions<any>();

interface Props {
  hasEditPermissions: boolean;
}

export const SubOrganizationsTable = ({ hasEditPermissions }: Props) => {
  const { t } = useTranslation();

  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof any, string>>
  >({});

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('page.userSettings.organization.sub-organizations.table.headers.name'),
        mantineEditTextInputProps: {},
        Cell: ({ renderedCellValue, row }) => (
          <Flex align="center" gap="xs">
            {row.original._id ? (
              <ClickableRegion
                regionLabel={t(
                  'page.userSettings.organization.sub-organizations.represent-org-link',
                )}
                onClick={() =>
                  row?.original?._id ? representOrganization(row?.original?._id) : null
                }
                className="suborg-represent-link"
                aria-organizationId={row?.original?._id}
              >
                <span>{renderedCellValue}</span>
              </ClickableRegion>
            ) : (
              renderedCellValue
            )}
          </Flex>
        ),
      },
      {
        accessorKey: 'countMembers',
        header: t(
          'page.userSettings.organization.sub-organizations.table.headers.total-users',
        ),
        mantineEditTextInputProps: {},
        enableEditing: false,
      },
      {
        accessorKey: 'dateCreated',
        header: t(
          'page.userSettings.organization.sub-organizations.table.headers.date-created',
        ),
        mantineEditTextInputProps: {},
        enableEditing: false,
      },
      {
        accessorKey: 'organizationAdminNames',
        header: t(
          'page.userSettings.organization.sub-organizations.table.headers.admins',
        ),
        mantineEditTextInputProps: {},
        enableEditing: false,
      },
    ],
    [validationErrors],
  );

  const {
    data: fetchedChildOrganizations = [],
    isLoading: isLoadingOrganizationUsers,
    isFetching: isFetchingOrganizationUsers,
  } = useFetchChildOrganizations({ refetchOnWindowFocus: false });

  const [orgNameToEdit, setOrgNameToEdit] = useState<string>('');

  const { isLoading: isCreatingOrganizationUser } = usePostOrganizationUser();
  const { mutateAsync: postOrganization, isLoading: isUpdatingOrganization } =
    usePostOrganization();
  const { mutateAsync: deleteOrganization, isLoading: isDeletingOrganization } =
    useDeleteOrganization();

  const handleUpdateOrganization: MRT_TableOptions<any>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    const thisOrganization = {
      ...row.original,
      ...values,
    } as any;
    // Post as a child, edit only the name
    await postOrganization({ organization: thisOrganization, isChild: true });
    exitEditingMode();
  };

  const openDeleteConfirmModal = (row: MRT_Row<any>) =>
    modals.openConfirmModal({
      title: t('page.userSettings.organization.confirmDeleteModal.title'),
      children: (
        <Text>
          {t('page.userSettings.organization.confirmDeleteModal.body', {
            email: row.original.email,
          })}
        </Text>
      ),
      labels: { confirm: t('global.delete'), cancel: t('global.cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        deleteOrganization({
          organizationId: row.original?._id?.toString(),
        });
      },
    });

  const table = useMantineReactTable({
    ...defaultTableOptions,
    columns,
    data: fetchedChildOrganizations,
    getRowId: (row) => row._id?.toString(),
    enableFacetedValues: true,
    enableEditing: hasEditPermissions,
    enableRowActions: hasEditPermissions,
    enablePinning: true,
    onEditingRowSave: handleUpdateOrganization,
    initialState: {
      ...defaultTableOptions.initialState,
      columnVisibility: {},
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [{ id: '_id', desc: false }],
    },
    state: {
      isLoading: isLoadingOrganizationUsers,
      isSaving: isUpdatingOrganization,
      showLoadingOverlay:
        isFetchingOrganizationUsers ||
        isUpdatingOrganization ||
        isDeletingOrganization ||
        isCreatingOrganizationUser,
    },
    renderRowActions: hasEditPermissions
      ? ({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Tooltip label={t('global.edit')}>
              <ActionIcon
                onClick={() => table.setEditingRow(row)}
                aria-label={t('global.edit')}
              >
                <Icon icon="edit" aria-label={t('global.edit-icon')} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label={t('global.delete')}>
              <ActionIcon
                color="red"
                onClick={() => openDeleteConfirmModal(row)}
                aria-label={t('global.delete')}
              >
                <Icon icon="trash" aria-label={t('global.trash-icon')} />
              </ActionIcon>
            </Tooltip>
          </Box>
        )
      : undefined,
    renderEditRowModalContent: ({ row, table }) => {
      const [editingOrgName, setEditingOrgName] = useState<string>(
        `${row?.original?.name}`,
      );
      const organizationId = row?.original?._id?.toString();

      return (
        <Stack>
          <Title order={3}>
            {t('page.userSettings.organization.sub-organizations.add-organization')}
          </Title>
          <p>
            {t('page.userSettings.organization.sub-organizations.add-organization-p1')}
          </p>
          <TextInput
            label={t(
              'page.userSettings.organization.sub-organizations.table.headers.name',
            )}
            required
            error={validationErrors.name}
            value={editingOrgName}
            onChange={(event) => setEditingOrgName(event.currentTarget.value || '')}
          />
          <Flex justify="flex-end" gap="md">
            <Button
              type="button"
              onClick={() => {
                table?.setEditingRow(null);
                setEditingOrgName('');
              }}
              color="dark"
            >
              {t('global.cancel')}
            </Button>
            <Button
              onClick={() => {
                handleUpdateOrganization({
                  exitEditingMode: () => {
                    table?.setEditingRow(null);
                  },
                  row,
                  table,
                  values: {
                    name: editingOrgName,
                    _id: organizationId,
                  },
                });
                setEditingOrgName('');
              }}
              type="submit"
            >
              {t('global.confirm')}
            </Button>
          </Flex>
        </Stack>
      );
    },
  });

  return <MantineReactTable table={table} />;
};
