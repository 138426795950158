import {
  AssetLevelOfSupport,
  InterfaceOrganizationComponent,
} from '@manifest-cyber/types/interface/dbTables';
import { Box, Flex, Stack, Text, Tooltip } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MappedOrganizationComponentWithVulns } from '../../api/componentsApi/components.mapper';
import { useUpdateComponent } from '../../api/componentsApi/useUdpateComponent';
import ClickableRegion from '../../components/ClickableRegion';
import {
  ColumnConfig,
  ColumnsConfigPopover,
} from '../../components/ColumnsConfigPopover/ColumnsConfigPopover';
import { ComponentsLicenseCell } from '../../components/DataTables/ComponentsLicenseCell';
import DataTableFooter from '../../components/DataTables/DataTableFooter';
import { EditableRow } from '../../components/DataTables/EditableRow/EditableRow';
import { HeaderWithTooltip } from '../../components/DataTables/HeaderWithTooltip/HeaderWithTooltip';
import Icon from '../../components/Icon/Icon';
import { getDefaultTableOptions } from '../../components/MRT/ManifestMRT';
import SupplierModal from '../../components/SupplierModal';
import { useComponentsFilter } from '../../hooks/components/useComponentsFilter';
import {
  COMPONENTS_REACT_QUERY_KEY,
  useFetchComponents,
} from '../../hooks/queries/useFetchComponents';
import { useAuth } from '../../hooks/useAuth';
import { ManifestNotifications } from '../../hooks/utils/useNotifications';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { useURLandLocalStorageSortingState } from '../../hooks/utils/usePersistentStates';
import normalizeAuthor from '../../lib/normalizeAuthor';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import './ComponentsTable.scss';
import { EndOfSupportModal } from './ComponentsTable/EndOfSupportModal/EndOfSupportModal';
import { LastReleaseModal } from './ComponentsTable/LastReleaseModal/LastReleaseModal';
import { LevelOfSupportModal } from './ComponentsTable/LevelOfSupportModal/LevelOfSupportModal';
import {
  ComponentsColumnId,
  getDefaultComponentsColumnsConfig,
} from './api/pageConfig/componentsPageConfig.mapper';
import { useGetComponentsPageConfig } from './api/pageConfig/useGetComponentsPageConfig';
import { useUpdateComponentsPageConfig } from './api/pageConfig/useUpdateComponentsPageConfig';

export const renderText = (text: string = '') => {
  return (
    <Tooltip disabled={(text?.length ?? 0) <= 30} label={text}>
      <Box
        sx={{
          height: '3.125rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text
          lineClamp={2}
          sx={{
            wordWrap: 'break-word',
          }}
        >
          {text}
        </Text>
      </Box>
    </Tooltip>
  );
};

const defaultTableOptions =
  getDefaultTableOptions<MappedOrganizationComponentWithVulns>();

interface Props {
  assetId?: string;
}

export const ComponentsTable = ({ assetId }: Props) => {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const [currentOrgId] = useOrganizationId(null);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showSupportLevelModal, setShowSupportLevelModal] = useState(false);
  const [showLastReleaseModal, setShowLastReleaseModal] = useState(false);
  const [showEndOfSupportModal, setShowEndOfSupportModal] = useState(false);
  const [componentToEdit, setComponentToEdit] =
    useState<MappedOrganizationComponentWithVulns | null>(null);
  const queryClient = useQueryClient();
  const { mutateAsync: updateComponent, isLoading: isUpdatingComponent } =
    useUpdateComponent();
  const { getCurrentFilters, componentName, setComponentName } = useComponentsFilter();
  const { data: pageConfig } = useGetComponentsPageConfig({
    t,
    options: { placeholderData: { columnsConfig: getDefaultComponentsColumnsConfig(t) } },
  });

  const { mutateAsync: updateComponentsPageConfig } = useUpdateComponentsPageConfig();

  const currentColumnsConfig = pageConfig?.columnsConfig || [];
  const newColumns = useMemo<MRT_ColumnDef<MappedOrganizationComponentWithVulns>[]>(
    () => [
      {
        accessorFn: (row) => row.lastReleaseDate,
        id: 'lastReleaseDate',
        header: t('tables.components.headers.lastReleaseDate'),
        minSize: 250,
        Header: ({ column }) => (
          <HeaderWithTooltip
            text={column.columnDef.header}
            tooltip={t('tables.components.headers.lastReleaseDateTooltip')}
          />
        ),
        Cell: ({ row }) => {
          return (
            <EditableRow
              useMutedText={!row.original.lastReleaseDateText}
              onClick={() => {
                setComponentToEdit(row.original);
                setShowLastReleaseModal(true);
              }}
            >
              {renderText(row.original.lastReleaseDateText || '--')}
            </EditableRow>
          );
        },
      },
      {
        accessorFn: (row) => row.levelOfSupport,
        id: 'levelOfSupport',
        header: t('tables.components.headers.levelOfSupport'),
        Cell: ({ row }) => {
          return (
            <EditableRow
              useMutedText={!row.original.levelOfSupportText}
              onClick={() => {
                setComponentToEdit(row.original);
                setShowSupportLevelModal(true);
              }}
            >
              {renderText(row.original.levelOfSupportText || '--')}
            </EditableRow>
          );
        },
      },
      {
        accessorFn: (row) => row.endOfSupport,
        id: 'endOfSupport',
        header: t('tables.components.headers.endOfSupport'),
        Cell: ({ row }) => {
          return (
            <EditableRow
              useMutedText={!row.original.endOfSupportText}
              onClick={() => {
                setComponentToEdit(row.original);
                setShowEndOfSupportModal(true);
              }}
            >
              {renderText(row.original.endOfSupportText || '--')}
            </EditableRow>
          );
        },
      },
    ],
    [],
  );

  const columns = useMemo<MRT_ColumnDef<MappedOrganizationComponentWithVulns>[]>(() => {
    const allColumns: MRT_ColumnDef<MappedOrganizationComponentWithVulns>[] = [
      {
        accessorFn: (row) => row.name,
        id: 'fullyQualifiedName',
        header: t('tables.components.headers.name'),
        Cell: ({ row }) => {
          if (row.original?._id) {
            return (
              <ClickableRegion
                className="table-link"
                regionLabel="View Component Details"
                href={`/component/${row.original?._id}/${currentOrgId || ''}`}
              >
                {row.original.nameToDisplay}
              </ClickableRegion>
            );
          }
          return renderText(row.original?.name);
        },
      },
      {
        accessorFn: (row) => renderText(row.version || ''),
        id: 'version',
        header: t('tables.components.headers.version'),
      },
      {
        accessorFn: (row) => renderText(row.derivedEcosystem || ''),
        id: 'derivedEcosystem',
        header: t('tables.components.headers.ecosystem'),
      },
      {
        accessorFn: (row) => row.vulnerabilities,
        id: 'countVulnerabilities.total',
        header: t('tables.components.headers.vulns'),
        Cell: ({ row }) => {
          const vulns = row.original.vulnerabilities || [];
          const cveIds = vulns.map((v) => v.cveId);
          if (cveIds?.length) {
            if (cveIds.length > 1) {
              return (
                <Tooltip
                  multiline
                  width={200}
                  label={
                    <Stack>
                      {cveIds.map((cveId) => (
                        <Text>{cveId}</Text>
                      ))}
                    </Stack>
                  }
                >
                  <Flex wrap="nowrap">
                    {cveIds.slice(0, 1).join(', ')}...{' '}
                    <Text color="cyan">+{cveIds.length - 1}</Text>
                  </Flex>
                </Tooltip>
              );
            }
            return cveIds.join(', ');
          }
          return <span className="table-na">{t('global.noneFound')}</span>;
        },
      },
      {
        accessorFn: (row) => row.licensesData,
        id: 'licensesData.fullName',
        header: t('tables.components.headers.license'),
        Cell: ComponentsLicenseCell,
      },
      {
        enableSorting: false,
        accessorFn: (row) => row.supplier,
        id: 'supplier.name',
        header: t('tables.components.headers.source'),
        Header: ({ column }) => (
          <HeaderWithTooltip
            text={column.columnDef.header}
            tooltip={t('tables.components.headers.source-tooltip')}
          />
        ),
        Cell: ({ row }) => {
          const normalizedSource = normalizeAuthor(row.original, t, 'author');

          const normalizedAuthor =
            normalizedSource === 'unknown-source'
              ? t('jargon.unknown-source')
              : normalizedSource;

          return (
            <>
              <span
                className={`${normalizedSource === 'unknown-source' ? 'table-na' : ''}`}
              >
                {renderText(normalizedAuthor)}
                {hasWriteAccess && (
                  <ClickableRegion
                    regionLabel="Modify source information."
                    onClick={() => {
                      setComponentToEdit(row.original);
                      setShowSupplierModal(true);
                    }}
                    className="inline-list-link"
                  >
                    <>
                      <Icon icon="pencil" />
                    </>
                  </ClickableRegion>
                )}
              </span>
            </>
          );
        },
      },
      {
        accessorFn: (row) => row.description,
        id: 'description',
        header: t('tables.components.headers.description'),
        Cell: ({ cell }) => {
          const cellValue = cell.getValue<string>();
          if (cellValue?.length) {
            return renderText(cellValue);
          }
          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
      {
        accessorFn: (row) => row.dateModified,
        id: 'dateModified',
        header: t('tables.components.headers.lastUpdated'),
        Header: ({ column }) => (
          <HeaderWithTooltip
            text={column.columnDef.header}
            tooltip={t('tables.components.headers.lastUpdatedTooltip')}
          />
        ),
        Cell: ({ row }) => renderText(row.original.dateModifiedText),
      },
      ...newColumns,
    ];

    return allColumns.filter((column) => {
      const columnConfig = currentColumnsConfig.find((c) => c.id === column.id);
      return columnConfig?.visible;
    });
  }, [currentColumnsConfig]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState('components', []);

  const {
    data: {
      data: fetchedComponents = [],
      queryInfo: { totalCount: countComponents = 0 } = {},
    } = {},
    isFetching: isFetchingComponents,
    isLoading: isLoadingComponents,
    isError: isErrorLoadingComponents,
  } = useFetchComponents({
    queryParams: {
      assetId,
      sort: sorting[0],
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      filters: getCurrentFilters(),
    },
    translations: {
      activelyMaintained: t('tables.components.supportLevel.activelyMaintained'),
      noMaintained: t('tables.components.supportLevel.noMaintained'),
      abandoned: t('tables.components.supportLevel.abandoned'),
      unknown: t('global.unknown'),
    },
  });

  //prefetch the next page for faster navigation
  useFetchComponents({
    queryParams: {
      assetId,
      sort: sorting[0],
      page: pagination.pageIndex + 2,
      limit: pagination.pageSize,
      filters: getCurrentFilters(),
    },
  });

  const table = useMantineReactTable<MappedOrganizationComponentWithVulns>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: fetchedComponents,
    enableFilters: false,
    enablePagination: false, // Disable pagination for this table
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: countComponents,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: true,
      columnPinning: {
        left: [...currentColumnsConfig.filter((col) => col.locked).map((col) => col.id)],
      },
      columnOrder: [...currentColumnsConfig.map((col) => col.id)],
    },
    state: {
      globalFilter: componentName,
      pagination,
      sorting,
      isLoading: isLoadingComponents,
      showLoadingOverlay: isFetchingComponents,
      columnPinning: {
        left: [...currentColumnsConfig.filter((col) => col.locked).map((col) => col.id)],
      },
      columnOrder: [...currentColumnsConfig.map((col) => col.id)],
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setComponentName,
    mantineSearchTextInputProps: {
      placeholder: t('tables.components.search-components'),
      sx: {
        minWidth: '260px',
      },
    },
    renderTopToolbar: ({ table }) => (
      <Flex justify="space-between" m="1rem 0">
        <Flex gap="xs">
          <MRT_GlobalFilterTextInput table={table} />
          <div>
            <ColumnsConfigPopover
              columns={currentColumnsConfig}
              onChange={async (columConfig) => {
                try {
                  await updateComponentsPageConfig({
                    columnsConfig: columConfig as ColumnConfig<ComponentsColumnId>[],
                  });
                } catch (error) {
                  ManifestNotifications.showErrorNotification({
                    title: t('global.unexpectedError'),
                    message: t('global.unexpectedErrorSubtitle'),
                  });
                  captureExceptionWithMessage('Error updateComponentsPageConfig', error);
                }
              }}
              title={t('vulnerabilities.table.controls.customizeColumns')}
            />
          </div>
        </Flex>
      </Flex>
    ),
  });

  const updateComponentInfo = async ({
    levelOfSupport,
    endOfSupport,
    lastReleaseDate,
    updatedFieldName,
  }: {
    levelOfSupport?: AssetLevelOfSupport;
    endOfSupport?: string;
    lastReleaseDate?: string;
    updatedFieldName: string;
  }) => {
    if (!componentToEdit?._id) {
      return;
    }

    try {
      await updateComponent({
        id: componentToEdit._id,
        body: {
          levelOfSupport,
          endOfSupport,
          lastReleaseDate,
        },
      });

      ManifestNotifications.showSuccessNotification({
        title: t('tables.components.savedNewValue', { field: updatedFieldName }),
        message: `${t('tables.components.forComponent')}: ${componentToEdit.nameToDisplay}`,
      });
    } catch (error) {
      ManifestNotifications.showErrorNotification({
        title: t('global.unexpectedError'),
        message: t('global.unexpectedErrorSubtitle'),
      });
    }
  };

  return (
    <>
      {isErrorLoadingComponents && (
        <ul className="page-errors anim-slideInUpShort">
          <li>{t('tables.components.unable-to-fetch-components')}</li>
        </ul>
      )}
      {componentToEdit && (
        <SupplierModal
          opened={showSupplierModal}
          onCancel={() => {
            setComponentToEdit(null);
            setShowSupplierModal(false);
          }}
          onConfirm={() => {
            queryClient.invalidateQueries([COMPONENTS_REACT_QUERY_KEY]);
            setComponentToEdit(null);
            setShowSupplierModal(false);
          }}
          component={componentToEdit as InterfaceOrganizationComponent}
        />
      )}
      <div className="list-components">
        <MantineReactTable table={table} />
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={countComponents}
          onChangePage={setPagination}
        />
        {showSupportLevelModal && componentToEdit && (
          <LevelOfSupportModal
            initialValue={componentToEdit.levelOfSupport as AssetLevelOfSupport}
            componentName={componentToEdit.nameToDisplay}
            showLoadingButton={isUpdatingComponent}
            onUpdate={async (levelOfSupport) => {
              await updateComponentInfo({
                levelOfSupport,
                updatedFieldName: t('tables.components.headers.levelOfSupport'),
              });
              setComponentToEdit(null);
              setShowSupportLevelModal(false);
            }}
            onClose={() => {
              setComponentToEdit(null);
              setShowSupportLevelModal(false);
            }}
          />
        )}
        {showEndOfSupportModal && componentToEdit && (
          <EndOfSupportModal
            initialValue={componentToEdit.endOfSupport}
            componentName={componentToEdit.nameToDisplay}
            showLoadingButton={isUpdatingComponent}
            onUpdate={async (endOfSupport) => {
              await updateComponentInfo({
                endOfSupport,
                updatedFieldName: t('tables.components.headers.endOfSupport'),
              });
              setComponentToEdit(null);
              setShowEndOfSupportModal(false);
            }}
            onClose={() => {
              setComponentToEdit(null);
              setShowEndOfSupportModal(false);
            }}
          />
        )}
        {showLastReleaseModal && componentToEdit && (
          <LastReleaseModal
            initialValue={componentToEdit.lastReleaseDate}
            componentName={componentToEdit.nameToDisplay}
            showLoadingButton={isUpdatingComponent}
            onUpdate={async (lastReleaseDate) => {
              await updateComponentInfo({
                lastReleaseDate,
                updatedFieldName: t('tables.components.headers.lastReleaseDate'),
              });
              setComponentToEdit(null);
              setShowLastReleaseModal(false);
            }}
            onClose={() => {
              setComponentToEdit(null);
              setShowLastReleaseModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};
