import { Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import hitApi from '../../../api';
import BasicSearch from '../../../components/BasicSearch';
import { useDeleteLabel } from '../../../hooks/labels/useDeleteLabel';
import { usePostLabel } from '../../../hooks/labels/usePostLabel';
import { usePutLabel } from '../../../hooks/labels/usePutLabel';
import { LabelInterface } from '../../../hooks/queries/useFetchLabels';
import { useAuth } from '../../../hooks/useAuth';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../../scss/pages/labels.scss';
import Label from './Label';

export type InterfaceManageableLabel = Omit<LabelInterface, 'dateCreated'> & {
  new?: boolean;
  isEditing?: boolean;
  dateCreated: Date;
};

function Labels() {
  const { t } = useTranslation();
  const [allLabels, setAllLabels] = useState<any[]>([]);
  const [labels, setLabels] = useState<InterfaceManageableLabel[]>([]);
  const [newLabelButtonDisabled, setNewLabelButtonDisabled] = useState(false);
  const { mutateAsync: createLabel } = usePostLabel();
  const { mutateAsync: updateLabel } = usePutLabel();
  const { mutateAsync: deleteLabel } = useDeleteLabel();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');

  const fetchLabels = async () => {
    try {
      const { success, data } = await hitApi.get('/labels');
      if (success) {
        return data;
      } else {
        throw new Error(t('page.userSettings.labels.error.fetch'));
      }
    } catch (e) {
      captureExceptionWithMessage('fetchLabels', e);
    }
  };
  useEffect(() => {
    fetchLabels()
      .then((res) => {
        const labels = res.map((label: LabelInterface) => {
          const { _id, name, color, dateCreated, includedInCount } = label;
          return {
            _id,
            name,
            color,
            includedInCount,
            dateCreated: dateCreated ? new Date(dateCreated) : new Date(),
            isEditing: false,
          };
        });
        setLabels(labels);
        setAllLabels(labels);
        setNewLabelButtonDisabled(false);
      })
      .catch((err) => {
        captureExceptionWithMessage('fetchLabels', err);
      });
  }, []);

  const handleSearch = (searchValue: string) => {
    // handle search with an empty string means that we are clearing the filter
    if (!searchValue) {
      return setLabels(allLabels);
    }
    const filteredLabels = allLabels.filter((label: any) => {
      return label?.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });
    setLabels(filteredLabels);
  };

  const startCreateNewLabel = () => {
    const newLabel = {
      new: true,
      isEditing: true,
    };
    setLabels((prevLabels: any[]) => {
      return [newLabel, ...prevLabels];
    });
    setNewLabelButtonDisabled(true);
  };

  const handleUpdate = async (_id: string, name: string, color: string) => {
    try {
      if (!_id || !name || !color) {
        throw new Error(t('page.userSettings.labels.error.color-name'));
      }
      if (!name || name?.length < 1 || name?.length > 200) {
        throw new Error(t('page.userSettings.labels.error.length'));
      }

      const { success } = await updateLabel({ id: _id, name, color });
      if (!success) {
        throw new Error(t('page.userSettings.labels.error.update'));
      }
      setAllLabels((prevLabels) => {
        return prevLabels.map((label) => {
          if (label?._id === _id) {
            return { ...label, name, color };
          }
          return label;
        });
      });
      setLabels((prevLabels) => {
        return prevLabels.map((label) => {
          if (label._id?.toString() === _id) {
            return { ...label, name, color };
          }
          return label;
        });
      });
    } catch (e) {
      captureExceptionWithMessage('labels handleUpdate', e);
    }
  };

  const handleInsert = async (labelName: string, labelColor: string) => {
    try {
      if (!labelName || !labelColor) {
        throw new Error(t('page.userSettings.labels.error.color-name'));
      }
      const { success, data } = await createLabel({
        name: labelName,
        color: labelColor,
        type: 'asset',
      });

      if (!data?.[0]) {
        throw new Error('no data found');
      }

      const [{ _id, name, color, dateCreated }] = data;
      const newLabel = {
        _id,
        name,
        color,
        includedInCount: 0,
        dateCreated: dateCreated ? new Date(dateCreated) : new Date(),
        isEditing: false,
      };
      setLabels((prevlabels) => {
        const labels = prevlabels.filter((label) => {
          return label?.new !== true;
        });
        return [newLabel, ...labels];
      });
      setAllLabels((prevLables) => {
        return [newLabel, ...prevLables];
      });
    } catch (e) {
      captureExceptionWithMessage('createLabel', e);
    }
    setNewLabelButtonDisabled(false);
  };

  const checkIfLabelExists = (labelName?: string): boolean => {
    const foundLabel = allLabels.find((label) => {
      return label.name === labelName;
    });
    return foundLabel ? true : false;
  };

  const handleDelete = async (_id: string | undefined) => {
    //_id as undefined means we clicked create new label and then clicked cancel
    // so the label never got staved and does not have an id
    try {
      if (_id) {
        const { success } = await deleteLabel({ id: _id });
        if (!success) {
          throw new Error(t('page.userSettings.labels.error.deletion'));
        }
        setAllLabels((prevAllLabels) => {
          return prevAllLabels.filter((label) => {
            return label._id !== _id;
          });
        });
      } else {
        //no id means we are no longer creating a new label so we can re enable new label creation
        setNewLabelButtonDisabled(false);
      }
      setLabels((prevLabels) => {
        return prevLabels.filter((label) => {
          if (_id) {
            return label._id?.toString() !== _id;
          } else {
            return label._id !== undefined;
          }
        });
      });
    } catch (e) {
      captureExceptionWithMessage('labels handleDelete', e);
    }
  };

  return (
    <div className="page-labels">
      <div className="header">{t('page.userSettings.labels.header')}</div>
      <div className="sub-header">{t('page.userSettings.labels.subHeader')}</div>
      <div className="labels-actions">
        <BasicSearch handleSearch={handleSearch} minCharCount={1} />
        {hasWriteAccess && (
          <Button onClick={startCreateNewLabel} disabled={newLabelButtonDisabled}>
            {t('page.userSettings.labels.newLabel')}
          </Button>
        )}
      </div>
      <div className="labels">
        {labels.map((label: InterfaceManageableLabel) => {
          return (
            <Label
              key={label._id?.toString() || uuidv4()}
              label={label}
              updateFn={handleUpdate}
              insertFn={handleInsert}
              deleteFn={handleDelete}
              checkExistFn={checkIfLabelExists}
              hasWriteAccess={hasWriteAccess}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Labels;
