import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import hitApi from '../../../../../api';
import Icon from '../../../../../components/Icon/Icon';
import Loading from '../../../../../components/Loading';
import { useAuth } from '../../../../../hooks/useAuth';
import { getOrgColorCode, getOrgInitials } from '../../../../../lib/orgIconColor';
import { captureExceptionWithMessage } from '../../../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export const GitHubIntegrationConfirm = () => {
  const { t } = useTranslation();
  const { user, currentOrgId } = useAuth();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<string[]>([]);

  // We'll verify first, then redir or map based on installation status (if valid)
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [verificationParams, setVerificationParams] = useState<{
    code?: string | null;
    installation_id?: string | null;
    setup_action?: string | null;
  } | null>(null);
  const [verificationResult, setVerificationResult] = useState<{
    organizationIntegrationId: any;
    githubIntegrationId: any;
    hasMapping: boolean;
    mappedOrganization: any;
    countRepositories: number;
  }>(); // TODO: type

  const [isMapping, setIsMapping] = useState<boolean>(false);
  const [isMapped, setIsMapped] = useState<boolean>(false);

  const organizationData = user?.organizationData;

  const getOrgIcon = (name: string) => {
    if (!name) return <></>;
    const iconText = getOrgInitials(name);
    const orgIconColor = getOrgColorCode(name);
    return (
      <div className={`org-icon ${orgIconColor}`} style={{ background: orgIconColor }}>
        {iconText}
      </div>
    );
  };

  const handleMapNewOrganization = async (organizationId: string) => {
    setIsMapping(true);

    try {
      const mapOrgs = await hitApi.post('integration/github/mapping', true, {
        organizationIntegrationId: `${
          verificationResult?.organizationIntegrationId || ''
        }`,
        manifestOrganizationId: `${organizationId}`,
      });

      const navigateUrl = '/settings/integrations/github/organizations';
      if (mapOrgs && mapOrgs.success) {
        setIsMapped(true);
        // Should be mapped, show mapped and redirect to settings page
        navigate(navigateUrl);
      } else {
        setErrors([
          ...errors,
          t('page.userSettings.integrations.github.error.mapping-failed'),
        ]);
        captureExceptionWithMessage('Issue mapping orgs', mapOrgs);
      }
    } catch (err) {
      setErrors([
        ...errors,
        t('page.userSettings.integrations.github.error.mapping-failed'),
      ]);
    }
  };

  useEffect(() => {
    const fetchVerificationIfExists = async () => {
      const verificationParams = {
        code: searchParams.get('code') || null,
        installation_id: searchParams.get('installation_id') || null,
        setup_action: searchParams.get('setup_action') || null,
      };
      if (
        !verificationParams.code ||
        !verificationParams.installation_id ||
        !verificationParams.setup_action
      ) {
        setErrors([
          ...errors,
          t('page.userSettings.integrations.github.error.invalid-params'),
        ]);
      } else {
        setVerificationParams(verificationParams);
        setIsVerifying(true);

        try {
          const fetchVerification = await hitApi.post(
            'integration/github/verify-installation',
            true,
            {
              ...verificationParams,
            },
          );

          const navigateUrl = '/settings/integrations/github/organizations';
          if (fetchVerification && fetchVerification.success) {
            // Should be verified, yay.
            // If we already have a mapping and orgId, set our orgId and redirect
            if (
              fetchVerification?.data[0]?.hasMapping &&
              fetchVerification?.data[0]?.mappedOrganization
            ) {
              navigate(navigateUrl);
            } else {
              // Need to map org, let user choose
              setVerificationResult(fetchVerification?.data[0] || {});
              setIsVerified(true);
            }
          } else {
            setErrors([
              t('page.userSettings.integrations.github.error.verification-failed'),
            ]);
          }
        } catch (err) {
          captureExceptionWithMessage('Unexpected error fetching verification', err);
          setErrors([
            ...errors,
            t('page.userSettings.integrations.github.error.invalid-params'),
          ]);
        }

        setIsVerifying(false);
      }
    };

    fetchVerificationIfExists();
  }, []);

  return (
    <>
      {errors.length > 0 && (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      )}

      {isVerifying && (
        <Loading
          customLoadingMessage={t(
            'page.userSettings.integrations.github.verifying-loader',
          )}
        />
      )}

      {isVerified && isMapping && <Loading />}

      {isVerified && !isMapping && (
        <>
          <h4>{t('page.userSettings.integrations.github.account-choice')}</h4>
          <div>
            {organizationData &&
              Object.entries(organizationData).map(([orgId, orgData]) => {
                //@ts-ignore
                const { name } = orgData;
                return (
                  <div key={orgId} onClick={() => handleMapNewOrganization(`${orgId}`)}>
                    <div className="org-row">
                      <div className="org-name-container">
                        {getOrgIcon(name)}
                        <div className="org-name">&nbsp;{name}</div>
                      </div>
                      <Icon icon="angle-right" />
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}

      {isMapped && (
        <Loading
          customLoadingMessage={t('page.userSettings.integrations.github.cleaning-up')}
        />
      )}
    </>
  );
};
