import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { ColumnSort } from '@tanstack/react-table';
import hitApi from '..';
import { Filters } from '../../hooks/components/useComponentsFilter';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { buildUrlWithParams } from '../../lib/url/buildWithParams/buildWithParams';
import { InterfaceOrganizationComponentWithVulns } from '../../types/FullInterfaces';
import { TransformedToClientTypes } from '../../types/transformedToClientType';
import {
  ComponentsMapperTranslations,
  mapComponents,
  MapComponentsRetunValue,
} from './components.mapper';

export interface UpdateComponentPayload {
  author?: string;
  supplier?: string;
  publisher?: string;
  lastReleaseDate?: string;
  endOfSupport?: string;
  levelOfSupport?: string;
}

const updateComponent = async (id: string, payload: UpdateComponentPayload) => {
  const response: ManifestApiResponse<
    TransformedToClientTypes<InterfaceOrganizationComponent>
  > = await hitApi.post(`component/${id}`, true, payload);

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return successfulResponse;
};

export interface GetComponentUrlParams {
  limit?: number;
  page?: number;
  sort?: Partial<ColumnSort>;
  assetId?: string;
  filters?: {
    field: keyof Filters;
    value: unknown;
  }[];
}

const getRequestUrl = ({ sort, filters, ...rest }: GetComponentUrlParams): string => {
  const sortParam = sort?.id
    ? {
        [sort.id]: sort.desc ? -1 : 1,
        _id: sort.desc ? -1 : 1,
      }
    : undefined;

  return buildUrlWithParams(`components`, {
    sort: sortParam,
    filters: filters && JSON.stringify(filters),
    ...rest,
  });
};

const getComponents = async (
  payload: GetComponentUrlParams,
  translations?: ComponentsMapperTranslations,
): Promise<MapComponentsRetunValue> => {
  const response: ManifestApiResponse<
    TransformedToClientTypes<InterfaceOrganizationComponentWithVulns>[]
  > = await hitApi.get(ComponentsApi.getRequestUrl(payload));

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return mapComponents(successfulResponse, translations);
};

export const ComponentsApi = {
  updateComponent,
  getRequestUrl,
  getComponents,
};
