import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import hitApi from '../../../api';
import { useAuth } from '../../../hooks/useAuth';
import { useAllNotificationsDefinitions } from '../../../hooks/utils/useAllNotificationsDefinitions';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../../scss/pages/notifications.scss';
import Notification from './Notification';

function Notifications() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<any[]>([]);

  const allNotificationsDefinitions = useAllNotificationsDefinitions();
  let notificationSettings: any[] = [];

  const fetchActiveNotificationEvents = async () => {
    try {
      const response = await hitApi.get(`notificationSystem/activeEvents`);
      if (!response.success)
        throw new Error('unable to fetch user notification settings');
      const activeNotificationEvents = response.data;
      return activeNotificationEvents;
    } catch (e) {
      return null;
    }
  };

  const fetchUserNotificationSettings = async () => {
    try {
      const settings: any[] = [];
      for (const notification of notificationSettings) {
        const response = await hitApi.get(
          `user/notificationSettings?notificationEventId=${notification.notificationEventId}`,
        );
        if (!response.success)
          throw new Error('unable to fetch user notification settings');
        settings.push(...response.data);
      }
      return settings;
    } catch (e) {
      return [];
    }
  };

  const updateUserNotificationSettings = async (
    notificationId: string,
    updatedSettings: any,
  ) => {
    try {
      if (!user) {
        return;
      }

      const [email] = user?.decryptedEmails;
      const notification = notifications.find((n) => n.id === notificationId);
      const { filters, integrations, active } = updatedSettings;
      const updates = {
        filters: filters.map((filter: any) => {
          return {
            id: filter.id,
            value: filter.value,
          };
        }),
        integrations: integrations.value,
        destinations: [{ type: 'email', destinations: [email] }],
        active,
      };
      if (!notification)
        throw new Error('failed trying to update an invalid notification');
      const response = await hitApi.put(
        `user/notificationSettings/${notification?.notificationEventId}`,
        true,
        updates,
      );
      if (!response.success) {
        throw new Error('unable to update user notification settings');
      }
    } catch (e) {
      return null;
    }
  };

  const loadNotificationSettings = (setting: any) => {
    const { active, filters, deliveryMethods: integrationsValues } = setting;
    const index = notificationSettings.findIndex(
      (n: any) => n.notificationEventId === setting.notificationEventId,
    );
    if (index > -1) {
      notificationSettings[index].active = active;
      notificationSettings[index].integrations.value = integrationsValues;
      notificationSettings[index].filters = notificationSettings[index].filters.map(
        (filter: any) => {
          const incomingValue = filters.find((f: any) => f.id === filter.id);
          return { ...filter, value: incomingValue.value };
        },
      );
    }
  };

  useEffect(() => {
    fetchActiveNotificationEvents()
      .then((activeNotifications) => {
        const activeNotificationNames = activeNotifications.map((an: any) => an.name);
        notificationSettings = allNotificationsDefinitions
          .filter((notification) => {
            return (
              activeNotificationNames.includes(notification.id) &&
              notification.type === 'userNotification'
            );
          })
          .map((notification) => {
            const { _id: notificationEventId } = activeNotifications.find(
              (an: any) => notification.id === an.name,
            );
            return { ...notification, notificationEventId };
          });
      })
      .then(() => {
        return fetchUserNotificationSettings();
      })
      .then((settings) => {
        if (settings.length) {
          settings.forEach((setting) => {
            loadNotificationSettings(setting);
          });
        }
        setNotifications(notificationSettings);
      })
      .catch((e) => {
        captureExceptionWithMessage('fetchActiveNotificationEvents', e);
      });
  }, []);

  const handleNotificationSettingsSave = async (
    notificationId: string,
    notificationSettings: any,
  ) => {
    try {
      await updateUserNotificationSettings(notificationId, notificationSettings);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="page-notifications">
      <div className="header">{t('page.userSettings.notifications.header')}</div>
      <div className="divider" />
      {notifications.map((notification: any) => {
        return (
          <Notification
            key={notification.id}
            notification={notification}
            onSave={handleNotificationSettingsSave}
          />
        );
      })}
    </div>
  );
}

export default Notifications;
